import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from 'src/app/models/menu-items.model';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  toggleSideBar$: Observable<boolean> = this.menuService.menuToggle$;
  menuItems$: Observable<MenuItem[]> = this.menuService.menuItems$;
  constructor(private menuService: MenuService) {}
}
