import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayRoutingModule } from './display-routing.module';
import { DisplayTrackComponent } from './display-track/display-track.component';
import { ArrivalDepartureComponent } from './arrival-departure/arrival-departure.component';
import { OutOfOrderComponent } from './out-of-order/out-of-order.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { HeaderComponent } from './header/header.component';
import { DisplayStationTrackComponent } from './station-track/display-station-track.component';
import { IndicatorComponent } from './indicator/indicator.component';
import { DisplayStationComponent } from './station/display-station.component';
import { BlinkDirective } from '../../../../../directives/blink.directive';

@NgModule({
  declarations: [
    DisplayTrackComponent,
    ArrivalDepartureComponent,
    OutOfOrderComponent,
    WelcomeComponent,
    NotificationsComponent,
    HeaderComponent,
    DisplayStationTrackComponent,
    IndicatorComponent,
    DisplayStationComponent,
    BlinkDirective,
  ],
  imports: [CommonModule, DisplayRoutingModule],
})
export class DisplayModule {}
