import { Injectable } from '@angular/core';
import { MESSAGE } from '../utils/messages';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private _currentLang: string = 'sr-Latin';
  private _dictionary: any = {
    'sr-Latin': MESSAGE,
  };

  private translate(key: string): string {
    if (this._dictionary[this._currentLang] && this._dictionary[this._currentLang][key]) {
      return this._dictionary[this._currentLang][key];
    } else {
      return key;
    }
  }

  public instant(key: string) {
    return this.translate(key);
  }
}
