<ul class="train-location" [ngClass]="{ canceled: canceled }">
  <li *ngFor="let trainLocation of trainLocations" [className]="trainLocation.status + ' ' + trainLocation.style">
    <span>
      <span class="item name">{{ trainLocation.station?.name }}</span>
      <span class="item">
        <ng-container *ngIf="trainLocation.arrival">
          {{ trainLocation.arrival | date : 'HH:mm' }}
          <ng-container *ngIf="trainLocation.arrivalDelay">({{ trainLocation.arrivalDelay }})</ng-container>
        </ng-container>
      </span>
      <span class="item">
        <ng-container *ngIf="trainLocation.departure">
          {{ trainLocation.departure | date : 'HH:mm' }}
          <ng-container *ngIf="trainLocation.departureDelay">({{ trainLocation.departureDelay }})</ng-container>
        </ng-container>
      </span>
    </span>
  </li>
</ul>
