<ng-container *ngIf="toggleSideBar$ | async; else closed">
  <a routerLink="/main" class="logo">
    <img src="/assets/img/avis-logo.svg" alt="logo" />
  </a>
  <nav class="opened">
    <ul>
      <li *ngFor="let item of menuItems$ | async; let i = index">
        <a
          class="menu-item"
          [routerLink]="item.path"
          routerLinkActive="active"
        >
          <mat-icon [svgIcon]="item.iconName"></mat-icon>
          <span>
            {{ item.name }}
          </span>
        </a>
      </li>
    </ul>
  </nav>
</ng-container>
<ng-template #closed>
  <a routerLink="/main" class="logo-small">
    <img src="/assets/img/avis-logo-small.svg" alt="logo" />
  </a>
  <nav class="closed">
    <ul>
      <li>
        <a
          class="menu-item"
          *ngFor="let item of menuItems$ | async; let i = index"
          [matTooltip]="item.name"
          matTooltipPosition="right"
          [routerLink]="item.path"
          routerLinkActive="active"
        >
          <mat-icon [svgIcon]="item.iconName"></mat-icon>
        </a>
      </li>
    </ul>
  </nav>
</ng-template>
