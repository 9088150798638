import { Directive, ElementRef, Input, QueryList, Renderer2 } from '@angular/core';

@Directive({
  selector: '[blink]',
})
export class BlinkDirective {
  @Input() blink: number;
  constructor(private el: ElementRef, protected _renderer2: Renderer2) {}

  blinkItem(animationDuration: string, animationIterationCount: number, item: 'plan' | 'delay' = 'plan') {
    let selector = '.blink';
    if (item === 'delay') {
      selector = '.blinkDelay';
    }
    let allChildren: QueryList<HTMLDivElement> = this.el.nativeElement.querySelectorAll(`:scope ${selector}`);
    allChildren.forEach(item => {
      this._renderer2.setStyle(item, 'animation-duration', animationDuration);
      this._renderer2.setStyle(item, 'animation-iteration-count', animationIterationCount);
    });
  }
}
