import { Component, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RealizationDetails } from '../../../../../models/realization.model';

@Component({
  selector: 'app-realization-details',
  templateUrl: './realization-details.component.html',
  styleUrls: ['./realization-details.component.scss'],
})
export class RealizationDetailsComponent {
  name: string = 'Realizacija';
  id: number = this.data.id;
  displayedColumns: string[] = [
    'ID',
    'STATION',
    'KOLOSEK',
    'TRANZIT',
    'TIME_ARRIVAL',
    'TIME_DEPARTURE',
    'ARRIVAL_DELAY',
    'DEPARTURE_DELAY',
    'STATUS',
  ];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { id: number; data: RealizationDetails },
    public dialogRef: MatDialogRef<RealizationDetailsComponent>,
  ) {}
}
