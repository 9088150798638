<div class="main-div">
  <h1 class="main-header-text">Nedeljni rasporedi</h1>
  <!---Table--->
  <div class="flex table-top-area">
    <div>
      <app-search></app-search>
    </div>
    <div class="add-station-div">
      <button mat-icon-button disableRipple (click)="openDialog()">
        <mat-icon svgIcon="add" class="add" matTooltip="Kreiraj nedeljni raspored"></mat-icon>
      </button>
    </div>
  </div>
  <!---Table--->
  <table class="table-style" mat-table [dataSource]="data" matSort>
    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id" id="week_id">ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Naziv</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="MON">
      <th class="cell-day" mat-header-cell *matHeaderCellDef>Pon</th>
      <td mat-cell *matCellDef="let element" class="cell-day">
        <mat-icon class="yes" svgIcon="yes" *ngIf="element.monday"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="TUE">
      <th class="cell-day" mat-header-cell *matHeaderCellDef>Uto</th>
      <td mat-cell *matCellDef="let element" class="cell-day">
        <mat-icon class="yes" svgIcon="yes" *ngIf="element.tuesday"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="WED">
      <th class="cell-day" mat-header-cell *matHeaderCellDef>Sre</th>
      <td mat-cell *matCellDef="let element" class="cell-day">
        <mat-icon class="yes" svgIcon="yes" *ngIf="element.wednesday"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="THU">
      <th class="cell-day" mat-header-cell *matHeaderCellDef>Čet</th>
      <td mat-cell *matCellDef="let element" class="cell-day">
        <mat-icon class="yes" svgIcon="yes" *ngIf="element.thursday"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="FRI">
      <th class="cell-day" mat-header-cell *matHeaderCellDef>Pet</th>
      <td mat-cell *matCellDef="let element" class="cell-day">
        <mat-icon class="yes" svgIcon="yes" *ngIf="element.friday"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="SAT">
      <th class="cell-day" mat-header-cell *matHeaderCellDef>Sub</th>
      <td mat-cell *matCellDef="let element" class="cell-day">
        <mat-icon class="yes" svgIcon="yes" *ngIf="element.saturday"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="SUN">
      <th class="cell-day" mat-header-cell *matHeaderCellDef>Ned</th>
      <td mat-cell *matCellDef="let element" class="cell-day">
        <mat-icon class="yes" svgIcon="yes" *ngIf="element.sunday"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="SPECIAL_DAY">
      <th mat-header-cell *matHeaderCellDef>Specijalni dan</th>
      <td mat-cell *matCellDef="let element">{{ returnSpecialDay(element.specialDayIndicator) }}</td>
    </ng-container>

    <ng-container matColumnDef="AKCIJA">
      <th mat-header-cell *matHeaderCellDef class="p-0">Akcije</th>
      <td mat-cell *matCellDef="let element" class="cell-size-actions">
        <div class="cell-actions">
          <button mat-icon-button (click)="getById(element.id)">
            <mat-icon svgIcon="edit" class="edit" matTooltip="Ažuriraj"></mat-icon>
          </button>
          <button mat-icon-button (click)="deleteDialog(element)">
            <mat-icon svgIcon="delete" class="delete" matTooltip="Obriši"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="paginator">
    <mat-paginator [length]="total" [pageSize]="10" [hidePageSize]="true" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>
