import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { SortDirection } from '@angular/material/sort';
import { BehaviorSubject, catchError, filter, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { ShowElement } from '../utils/enums';
import { InfoTable, InfoTableMessage, InfoTableTrackMessage, InfoTableType } from '../models/info-table';
import { RxStompService } from './rx-stomp.service';
import { ARRIVALS_DISPLAY, DEPARTURES_ARRIVALS_DISPLAY, DEPARTURES_DISPLAY } from '../utils/constants';
import { IPage } from '../models/shared.model';

@Injectable()
export class InfoTableService {
  private infoTableElementSource: BehaviorSubject<ShowElement> = new BehaviorSubject<ShowElement>(ShowElement.NONE);

  constructor(
    private activatedRoute: ActivatedRoute,
    private rxStompService: RxStompService,
    private client: HttpClient,
  ) {}

  monitor(
    stationId: number,
    pageNumber: number,
    pageSize: number,
    sortBy?: string,
    sortDirection?: SortDirection,
  ): Observable<IPage<InfoTable>> {
    let paramsObject: { [k: string]: number | string } = {
      stationId: stationId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    if (sortBy && sortDirection) {
      paramsObject['sortBy'] = sortBy;
      paramsObject['sortDirection'] = sortDirection.toUpperCase();
    }
    const params = new HttpParams({ fromObject: paramsObject });

    return this.client
      .get<IPage<InfoTable>>('info-tables/search', { params })
      .pipe(catchError(() => of({} as IPage<InfoTable>)));
  }

  get infoTableElement$(): Observable<ShowElement> {
    return this.infoTableElementSource.asObservable();
  }

  setInfoTableElement(element: ShowElement) {
    this.infoTableElementSource.next(element);
  }

  get infoTableTrackParams$(): Observable<InfoTableTrackMessage> {
    return this.activatedRoute.queryParams.pipe(
      filter(params => !!params['station-id'] && !!params['track'] && !!params['info-table-id']),
      map(params => ({
        stationId: params['station-id'],
        track: params['track'],
        infoTableId: params['info-table-id'],
        type: InfoTableType.TRACK,
      })),
      shareReplay(1),
    );
  }

  get infoTableParams$(): Observable<InfoTableMessage> {
    return this.activatedRoute.queryParams.pipe(
      filter(params => !!params['station-id'] && !!params['info-table-id']),
      switchMap(params =>
        this.activatedRoute.url.pipe(
          filter(url => !!url[0]?.path),
          map(url => ({ stationId: params['station-id'], infoTableId: params['info-table-id'], url: url[0].path })),
        ),
      ),
      map(params => {
        const isArrivalsDisplay = params.url === ARRIVALS_DISPLAY;
        const isDeparturesDisplay = params.url === DEPARTURES_DISPLAY;
        const isDepartureAndArrival = params.url === DEPARTURES_ARRIVALS_DISPLAY;

        let type: InfoTableType;

        if (isDepartureAndArrival) {
          type = InfoTableType.ARRIVAL_DEPARTURE;
        } else if (isArrivalsDisplay) {
          type = InfoTableType.ARRIVAL;
        } else {
          type = InfoTableType.DEPARTURE;
        }

        return {
          stationId: params.stationId,
          infoTableId: params.infoTableId,
          type,
        };
      }),
      shareReplay(1),
    );
  }

  ackTrack(infoTable: InfoTableTrackMessage) {
    this.rxStompService.publish({
      destination: '/monitor',
      body: JSON.stringify({
        infoTableId: infoTable.infoTableId,
        type: 'ACKNOWLEDGE',
        stationId: infoTable.stationId,
        infoTableType: infoTable.type,
        track: infoTable.track,
      }),
      retryIfDisconnected: false,
    });
  }

  ack(infoTable: InfoTableMessage) {
    this.rxStompService.publish({
      destination: '/monitor',
      body: JSON.stringify({
        infoTableId: infoTable.infoTableId,
        type: 'ACKNOWLEDGE',
        stationId: infoTable.stationId,
        infoTableType: infoTable.type,
      }),
      retryIfDisconnected: false,
    });
  }

  pingTrack(infoTable: InfoTableTrackMessage) {
    this.rxStompService.publish({
      retryIfDisconnected: false,
      destination: '/monitor',
      body: JSON.stringify({
        infoTableId: infoTable.infoTableId,
        type: 'PING',
        stationId: infoTable.stationId,
        infoTableType: infoTable.type,
        track: infoTable.track,
      }),
    });
  }

  ping(infoTable: InfoTableMessage) {
    this.rxStompService.publish({
      retryIfDisconnected: false,
      destination: '/monitor',
      body: JSON.stringify({
        infoTableId: infoTable.infoTableId,
        type: 'PING',
        stationId: infoTable.stationId,
        infoTableType: infoTable.type,
      }),
    });
  }
}
