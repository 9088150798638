import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { StationTrackRealizationResponse } from '../../../../../../models/display-realization.model';
import { DisplayStateData, DisplayStore } from '../../../../../../services/display.store';
import { BlinkDirective } from '../../../../../../directives/blink.directive';

@Component({
  selector: 'app-station-track',
  templateUrl: './display-station-track.component.html',
  styleUrls: ['./display-station-track.component.scss'],
  providers: [DisplayStore],
})
export class DisplayStationTrackComponent implements OnInit {
  @ViewChildren(BlinkDirective, { emitDistinctChangesOnly: true }) blink!: QueryList<BlinkDirective>;

  data: StationTrackRealizationResponse;
  blinkingSpeed: string = '500ms';
  blinkingCount: number = 20;

  @Input() set realizationResponse(response: StationTrackRealizationResponse) {
    this.data = response;
    this.blinkingSpeed = `${response.blinkingSpeed * 2}ms`;
    this.blinkingCount = Math.round(Number((response.blinkingTime * 1000) / response.blinkingSpeed / 2));
    const itemsMap = new Map<number, DisplayStateData>();
    response.stationRealizationItems?.map(item => {
      itemsMap.set(item.planInstanceItem.id, {
        plan: item.planInstanceItem.planInstanceId,
        delay: item.realizationItem.departureDelay ?? item.realizationItem.arrivalDelay ?? 0,
      });
    });
    this.displayStore.setRealizationItems(itemsMap);
  }
  @Input() notifications: string[];

  logoUrl(id: number): string {
    return `${environment.baseUrl}/operators/${id}/small-logo`;
  }

  constructor(private readonly displayStore: DisplayStore) {}

  ngOnInit(): void {
    this.displayStore.blink$.subscribe(blink => {
      blink.realizationItems.forEach((value, key) => {
        if (value) {
          const bdd = this.blink.filter(item => item.blink === key);
          if (bdd.length) {
            if (value.plan) {
              bdd.forEach(item => item.blinkItem(this.blinkingSpeed, this.blinkingCount));
            } else if (value.delay) {
              bdd.forEach(item => item.blinkItem(this.blinkingSpeed, this.blinkingCount, 'delay'));
            }
          }
        }
      });
    });
  }
}
