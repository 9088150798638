import { Component, EventEmitter, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { Observable, switchMap, tap } from 'rxjs';
import { RealizationService } from 'src/app/services/realization.service';
import { StationService } from 'src/app/services/station.service';
import { Station, Track } from '../../../../models/stations.model';
import {
  ARRIVALS_DISPLAY,
  DEPARTURES_ARRIVALS_DISPLAY,
  DEPARTURES_DISPLAY,
  SELECTED_STATION_KEY,
  TRACK_DISPLAY,
} from '../../../../utils/constants';
import { InfoTableType } from '../../../../models/info-table';

@Component({
  selector: 'app-display-info-tables',
  templateUrl: './display-info-tables.component.html',
  styleUrls: ['./display-info-tables.component.scss'],
})
export class DisplayInfoTablesComponent {
  form: FormGroup = this.fb.group({
    stationId: ['', Validators.required],
    track: [''],
  });
  stations$: Observable<Station[]> = this.stationService.getLicensed().pipe(
    tap(stations => {
      const selected = window.localStorage.getItem(SELECTED_STATION_KEY);
      if (stations.length === 1) {
        this.form.get('stationId')?.patchValue(stations[0].id);
        this.selectedStationId$.emit(stations[0].id);
      } else if (selected) {
        const selectedStation = JSON.parse(selected) as Station;
        if (stations.some(station => station.id === selectedStation.id)) {
          this.form.get('stationId')?.patchValue(selectedStation.id);
          this.selectedStationId$.emit(selectedStation.id);
        }
      }
    }),
  );
  selectedStationId$: EventEmitter<number> = new EventEmitter<number>();
  tracks$: Observable<Track[]> = this.selectedStationId$.pipe(
    switchMap(stationId => this.stationService.getTrackById(stationId)),
  );
  infoTableType = InfoTableType;

  constructor(
    private fb: FormBuilder,
    private stationService: StationService,
    private realizationService: RealizationService,
    private router: Router,
    private renderer: Renderer2,
  ) {}

  onSubmit(event: Event) {
    event.preventDefault();

    const stationId = this.form.value.stationId;
    const track = this.form.value.track;

    const queryParams: { [key: string]: any } = {
      'station-id': stationId,
      track: track,
      'info-table-id': -1,
    };

    const navigationExtras: NavigationExtras = {
      queryParams,
    };

    const url = this.router.createUrlTree([`/${TRACK_DISPLAY}`], navigationExtras).toString();
    const link = document.createElement('a');
    link.href = `#${url}`;
    link.target = '_blank';
    link.click();
  }

  arrivalDepartureTable(type: InfoTableType) {
    const stationId = this.form.value.stationId;
    switch (type) {
      case InfoTableType.DEPARTURE:
        this.navigateWithQueryParams(`/${DEPARTURES_DISPLAY}`, stationId);
        break;
      case InfoTableType.ARRIVAL:
        this.navigateWithQueryParams(`/${ARRIVALS_DISPLAY}`, stationId);
        break;
      case InfoTableType.ARRIVAL_DEPARTURE:
        this.navigateWithQueryParams(`/${DEPARTURES_ARRIVALS_DISPLAY}`, stationId);
        break;
    }
  }

  private navigateWithQueryParams(path: string, stationId: number) {
    const queryParams: { [key: string]: any } = {
      'station-id': stationId,
      'info-table-id': -1,
    };

    const navigationExtras: NavigationExtras = {
      queryParams,
    };

    const url = this.router.createUrlTree([path], navigationExtras).toString();
    const link = this.renderer.createElement('a');
    link.href = `#${url}`;
    link.target = '_blank';
    link.click();
  }

  getTracks($event: MatSelectChange) {
    this.form.get('track')?.reset();
    this.selectedStationId$.emit($event.value);
  }
}
