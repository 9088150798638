import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CacheData } from '../models/cache.model';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor(private http: HttpClient) {}

  get(): Observable<CacheData[]> {
    return this.http.get<CacheData[]>('cache');
  }

  delete(name: string): Observable<void> {
    const params = { cacheName: name };
    return this.http.post<void>('cache/delete', null, { params });
  }

  clear(): Observable<void> {
    return this.http.post<void>('cache/clear', null);
  }
}
