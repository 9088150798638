import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { map, Subscription, tap, timer } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnChanges {
  @Input() notifications: string[];
  @Input() switchTime: number;

  messages: Subscription = Subscription.EMPTY;
  message: string;
  maxLen = 147;

  ngOnChanges(changes: SimpleChanges): void {
    const switchTimeChange = changes['switchTime'];
    const notificationsChange = changes['notifications'];
    let change = false;
    let switchTime = this.switchTime;
    let notifications = this.notifications;

    if (switchTimeChange) {
      const curSwitchTime: number = switchTimeChange.currentValue;
      const prevSwitchTime: number = switchTimeChange.previousValue;
      if (curSwitchTime !== prevSwitchTime) {
        switchTime = curSwitchTime;
        change = true;
      }
    }

    if (notificationsChange) {
      const curNotifications: string[] = notificationsChange.currentValue;
      const prevNotifications: string[] = notificationsChange.previousValue;
      if (!this.compareArrays(curNotifications, prevNotifications)) {
        notifications = curNotifications;
        change = true;
      }
    }

    if (change) {
      this.displayNotifications(notifications, switchTime);
    }
  }

  protected compareArrays(a: string[], b: string[]) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  displayNotifications(notifications: string[], switchTime: number) {
    const notificationNo = notifications?.length;
    if (!notificationNo) {
      if (!this.messages.closed) {
        this.messages.unsubscribe();
      }
      this.message = '';
      return;
    } else {
      if (!this.messages.closed) {
        this.messages.unsubscribe();
      }
      if (notificationNo === 1) {
        this.message = notifications[0];
        return;
      }
    }
    let index = 0;
    this.messages = timer(0, switchTime * 1000)
      .pipe(
        tap(() => {
          if (index >= notificationNo) index = 0;
        }),
        map(() => notifications[index++]),
      )
      .subscribe(message => (this.message = message));
  }
}
