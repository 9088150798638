import { AfterViewInit, Component, EventEmitter, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { merge, Observable, Subscription, switchMap, tap } from 'rxjs';
import { AudioMessageData } from 'src/app/models/audio-message.model';
import { Station } from 'src/app/models/stations.model';
import { AudioMessageService } from 'src/app/services/audio-message.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { MESSAGE } from 'src/app/utils/messages';
import { SELECTED_STATION_KEY } from '../../../../utils/constants';
import { SearchComponent } from '../reusable-component/search/search.component';
import { ReportService } from '../../../../services/report.service';
import { MatRadioGroup } from '@angular/material/radio';
import { MessagesComponent } from './messages/messages.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-audio-messages',
  templateUrl: './audio-messages.component.html',
  styleUrls: ['./audio-messages.component.scss'],
  providers: [ReportService],
})
export class AudioMessagesComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatRadioGroup) select: MatRadioGroup;
  @ViewChild(SearchComponent) search: SearchComponent;
  maxDate = new Date();
  selectedDate: Date = new Date();
  selectedStation: Station;
  stationList = false;
  audioMessageData: AudioMessageData[] = [];
  pageSize = 10;
  total: number;
  displayedColumns: string[] = [
    'ID',
    'DATE',
    'TRAIN',
    'ROUTE_NAME',
    'TRACK',
    'PRIORITY',
    'MESSAGE',
    'CONTROLLER',
    'EMITTING',
  ];
  private subscription = new Subscription();
  private initialData = new EventEmitter<void>();
  stations$: Observable<Station[]> = this.userService.getAuthUserStations().pipe(
    tap(stations => {
      const selected = window.localStorage.getItem(SELECTED_STATION_KEY);
      if (stations.length === 1) {
        this.selectedStation = stations[0];
        this.storeSelectedStation(stations[0]);
        this.initialData.emit();
      } else if (selected) {
        const selectedStation = JSON.parse(selected) as Station;
        if (stations.some(station => station.id === selectedStation.id)) {
          this.selectedStation = selectedStation;
          this.initialData.emit();
        }
      }
    }),
  );

  constructor(
    private userService: UserService,
    private sharedService: SharedService,
    private audioMessageService: AudioMessageService,
    private reportService: ReportService,
    private renderer: Renderer2,
    private dialog: MatDialog,
  ) {}

  ngAfterViewInit(): void {
    this.subscription.add(
      merge(
        this.paginator.page,
        this.select.change,
        this.search.searchBox,
        this.initialData,
        this.sharedService.gridReload$,
      )
        .pipe(
          switchMap(events => {
            if (
              events instanceof MatSelectChange ||
              (typeof events === 'object' && events.hasOwnProperty('searchText'))
            ) {
              this.paginator.pageIndex = 0;
            }
            return this.audioMessageService.get(
              this.selectedStation.id,
              this.paginator.pageIndex,
              this.paginator.pageSize,
              'emittingDateFrom',
              'ASC',
              this.search.searchText,
            );
          }),
        )
        .subscribe({
          next: data => {
            if (data) {
              this.total = data.totalElements;
              this.audioMessageData = data.content;
            } else {
              this.audioMessageData = [];
            }
          },
          error: (error: any) => {
            this.sharedService.showMessage(error?.message || MESSAGE.error_get_realization);
          },
        }),
    );
  }

  storeSelectedStation(station: Station): void {
    this.selectedStation = station;
    window.localStorage.setItem(SELECTED_STATION_KEY, JSON.stringify(this.selectedStation));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getDailyStationAudioMessages() {
    this.reportService.dailyStationAudioMessages(this.selectedStation.id, this.selectedDate).subscribe(res => {
      const fileName =
        res.headers.get('content-disposition')?.split(';')[1]?.split('=')[1].replace(/\"/g, '') || 'Izveštaj.pdf';
      const blob = new Blob([res.body!], { type: res.body?.type });
      const a: HTMLAnchorElement = this.renderer.createElement('a');
      const url = URL.createObjectURL(blob!);
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    });
  }

  openDialog() {
    this.dialog.open(MessagesComponent, {
      width: '460px',
      height: 'auto',
      panelClass: 'custom-dialog-container',
      autoFocus: false,
    });
  }
}
