<div class="main-div">
  <div>
    <div class="station-menu">
      <div class="d-flex align-items-center">
        <span class="station-name">{{ selectedStation?.name || 'Izaberite stanicu' }}</span>
        <button mat-icon-button disableRipple aria-label="Stanica" (click)="stationList = !stationList">
          <mat-icon svgIcon="arrow-sort" class="arrow-sort"></mat-icon>
        </button>
      </div>
      <div class="station-list" [class.opened]="stationList">
        <p>Stanica</p>
        <mat-radio-group
          aria-labelledby="station-radio-group-label"
          class="station-radio-group"
          [value]="selectedStation"
          (change)="stationList = false; storeSelectedStation($event.value)"
          name="station"
        >
          <mat-radio-button
            color="primary"
            class="station-radio-button"
            *ngFor="let station of stations$ | async"
            [value]="station"
          >
            {{ station.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center table-top-area">
    <div>
      <app-search></app-search>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <div class="d-flex justify-content-end align-items-center">
        <input matInput disabled [max]="maxDate" [matDatepicker]="picker" [(ngModel)]="selectedDate" class="export-date border-0 bg-transparent">
        <mat-datepicker-toggle [for]="picker">
          <mat-icon matDatepickerToggleIcon svgIcon="calendar" class="calendar"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
      </div>
      <button type="button" mat-icon-button disableRipple (click)="getDailyStationAudioMessages()">
        <mat-icon svgIcon="export" class="export" matTooltip="Štampa poruka za dan"></mat-icon>
      </button>
      <button mat-icon-button disableRipple (click)="openDialog()" class="ml-3">
        <mat-icon svgIcon="add" class="add" matTooltip="Generisanje poruka"></mat-icon>
      </button>
    </div>
  </div>
  <table class="table-style" mat-table [dataSource]="audioMessageData">
    <ng-container matColumnDef="ID" class="id-width">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="DATE">
      <th mat-header-cell *matHeaderCellDef>Vreme<br/>emitovanja</th>
      <td mat-cell *matCellDef="let element">
        {{ element.emittingDateFrom | date : 'HH:mm:ss' }}
        <br />
        {{ element.emittingDateFrom | date : 'dd.MM.yyyy' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="TRAIN">
      <th mat-header-cell *matHeaderCellDef>Broj voz</th>
      <td mat-cell *matCellDef="let element">
        {{ element.planInstanceHeader?.train?.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ROUTE_NAME">
      <th mat-header-cell *matHeaderCellDef>Naziv rute</th>
      <td mat-cell *matCellDef="let element">
        {{ element.planInstanceHeader?.routeHeader?.firstStation?.name }}
        <br/>
        {{ element.planInstanceHeader?.routeHeader?.lastStation?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="TRACK">
      <th mat-header-cell *matHeaderCellDef>Kolosek</th>
      <td mat-cell *matCellDef="let element">{{ element.track }}</td>
    </ng-container>

    <ng-container matColumnDef="PRIORITY">
      <th mat-header-cell *matHeaderCellDef>Prioritet</th>
      <td mat-cell *matCellDef="let element">{{ element.priority }}</td>
    </ng-container>

    <ng-container matColumnDef="MESSAGE">
      <th mat-header-cell *matHeaderCellDef>Tekst poruke</th>
      <td mat-cell *matCellDef="let element">{{ element.messageText }}</td>
    </ng-container>

    <ng-container matColumnDef="CONTROLLER">
      <th mat-header-cell *matHeaderCellDef>Audio kontroler</th>
      <td mat-cell *matCellDef="let element">{{ element.audioController.name }}</td>
    </ng-container>

    <ng-container matColumnDef="EMITTING">
      <th mat-header-cell *matHeaderCellDef>Emituje se<br />trenutno</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.emitting" svgIcon="yes-action" class="yes-action"></mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="paginator">
    <mat-paginator [length]="total" [pageSize]="pageSize" [hidePageSize]="true" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>
