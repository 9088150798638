<div class="search-main">
  <mat-icon class="icon-div" svgIcon="search"></mat-icon>
  <div class="search-div">
    <input
      #search
      type="text"
      class="search-input search"
      placeholder="Pretraži... "
      appDelayedInput
      [delayTime]="600"
      (delayedInput)="send($event)"
    />
    <button *ngIf="search.value" disableRipple matSuffix mat-icon-button aria-label="Poništi" (click)="clear(search)">
      <mat-icon svgIcon="clear" class="clear" matTooltip="Poništi"></mat-icon>
    </button>
  </div>
</div>
