import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Premise, Station, StationData, SubsequentStation, Track } from '../models/stations.model';
import { SortDirection } from '@angular/material/sort';
import { IPage } from '../models/shared.model';
import { Components } from '../utils/enums';
import { SearchConnector } from './search.connector';

@Injectable({
  providedIn: 'root',
})
export class StationService {
  constructor(private http: HttpClient, private connector: SearchConnector) {}

  search(
    searchText: string,
    pageIndex: number,
    pageSize: number,
    active?: string,
    direction?: SortDirection,
  ): Observable<IPage<Station>> {
    return this.connector.search<Station>(searchText, pageIndex, pageSize, active, direction, Components.STATION);
  }

  upsert(data: StationData, id?: number): Observable<Station> {
    const url = id !== undefined ? `stations/${id}` : 'stations';
    return id !== undefined ? this.http.put<Station>(url, data) : this.http.post<Station>(url, data);
  }

  //Delete data
  delete(id: number) {
    return this.http.delete(`stations/${id}`);
  }

  postTracks(data: any, id: number): Observable<Station> {
    return this.http.post<Station>(`stations/${id}/tracks`, data);
  }

  //Get station by id
  getById(id: number): Observable<Station> {
    return this.http.get<Station>(`stations/${id}`);
  }

  //Get all stations
  get(): Observable<Station[]> {
    return this.http.get<Station[]>('stations');
  }

  //get all licensed stations
  getLicensed(): Observable<Station[]> {
    return this.http.get<Station[]>('stations/licensed');
  }

  //Get premises
  getStationPremises(id: number): Observable<Premise[]> {
    return this.http.get<Premise[]>(`stations/${id}/premises`);
  }

  postStationPremises(id: number, data: any) {
    return this.http.post<Station>(`stations/${id}/premises`, data);
  }

  deleteStationPremises(id: number) {
    return this.http.delete(`stations/premises/${id}`);
  }

  //Get subsequent station
  getSubsequentStations(id: number): Observable<SubsequentStation[]> {
    return this.http.get<SubsequentStation[]>(`stations/${id}/subsequent-stations`);
  }

  postSubsequentStation(id: number, data: any): Observable<SubsequentStation> {
    return this.http.post<SubsequentStation>('stations/' + id + '/subsequent-stations', data);
  }

  deleteSubsequentStation(id: number): Observable<void> {
    return this.http.delete<void>(`stations/subsequent-stations/${id}`);
  }

  getTrackById(id: number): Observable<Track[]> {
    return this.http.get<Track[]>(`stations/${id}/tracks`);
  }
}
