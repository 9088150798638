<app-action-dialog
  [title]="name"
  [submitTitle]="'Potvrdi'"
  [disabled]="disabled"
  (submitted)="onSubmit()"
  [planInstanceId]="data.id"
>
  <form [formGroup]="form">
    <div class="input-div">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Naziv stanice:</mat-label>
        <mat-select formControlName="station" (selectionChange)="onSelect($event)" [errorStateMatcher]="matcher">
          <mat-option *ngFor="let station of stations$ | async" [value]="station.station.id">
            {{ station.station.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="input-div">
      <mat-form-field class="w-100 name-field" appearance="outline">
        <mat-label class="label-mat">Kašnjenje</mat-label>
        <input matInput formControlName="delay" [errorStateMatcher]="matcher" type="number" />
      </mat-form-field>
    </div>
  </form>
</app-action-dialog>
