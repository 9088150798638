import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, EMPTY, Observable, of, Subscription, switchMap } from 'rxjs';
import { StationService } from '../../../../../services/station.service';
import { SharedService } from '../../../../../services/shared.service';
import { MESSAGE } from '../../../../../utils/messages';
import { Station, StationModalData, SubsequentStation } from '../../../../../models/stations.model';

@Component({
  selector: 'app-station-subsequent',
  templateUrl: './station-subsequent.component.html',
  styleUrls: ['./station-subsequent.component.scss'],
})
export class StationSubsequentComponent implements OnInit, OnDestroy {
  allStations$: Observable<Station[]> = this.stationService.get().pipe(
    catchError(error => {
      error.errorMessages.forEach((err: any) => {
        err ? this.sharedService.showMessage(err) : this.sharedService.showMessage(MESSAGE.error_station_get);
      });
      return of([] as Station[]);
    }),
  );
  subsequentStations: SubsequentStation[];
  subsequentStationForm: FormGroup = this.fb.group({
    selectedStation: ['', Validators.required],
  });
  displayedColumns = ['NAZIV SUSEDNE STANICE', 'AKCIJE'];
  subscription: Subscription = new Subscription();
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: StationModalData<SubsequentStation>,
    private stationService: StationService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<StationSubsequentComponent>,
  ) {}

  ngOnInit(): void {
    this.subsequentStations = this.data.content;
  }

  get selectedStation() {
    return this.subsequentStationForm.get('selectedStation') as FormControl;
  }

  removeSubsequentStation(id: number) {
    this.subscription.add(
      this.stationService
        .deleteSubsequentStation(id)
        .pipe(
          switchMap(() =>
            this.stationService.getSubsequentStations(this.data.id).pipe(
              catchError(error => {
                error.errorMessages.forEach((err: any) => {
                  err
                    ? this.sharedService.showMessage(err)
                    : this.sharedService.showMessage(MESSAGE.error_subsequent_station_load);
                });
                return EMPTY;
              }),
            ),
          ),
        )
        .subscribe({
          next: res => {
            this.subsequentStations = res;
            this.sharedService.openSnackBar(MESSAGE.success_delete);
          },
          error: errorMessage => {
            errorMessage.appCode
              ? this.sharedService.showMessage(errorMessage.appCode)
              : this.sharedService.showMessage(MESSAGE.error_subsequent_station_delete);
          },
        }),
    );
  }

  onClickAddSubStation() {
    if (!this.subsequentStationForm.valid) {
      this.sharedService.showMessage(MESSAGE.error_subsequent_station_input);
      return;
    }
    if (this.selectedStation?.value) {
      this.subscription.add(
        this.stationService
          .postSubsequentStation(this.data.id, {
            nextStationId: this.selectedStation.value.id,
          })
          .pipe(
            switchMap(() =>
              this.stationService.getSubsequentStations(this.data.id).pipe(
                catchError(error => {
                  error.errorMessages.forEach((err: any) => {
                    err
                      ? this.sharedService.showMessage(err)
                      : this.sharedService.showMessage(MESSAGE.error_subsequent_station_load);
                  });
                  return EMPTY;
                }),
              ),
            ),
          )
          .subscribe({
            next: res => {
              this.subsequentStations = res;
              this.sharedService.openSnackBar(MESSAGE.success_put);
            },
            error: errorMessage => {
              errorMessage.appCode
                ? this.sharedService.showMessage(errorMessage.appCode)
                : this.sharedService.showMessage(MESSAGE.error_subsequent_station_edit);
            },
          }),
      );
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
