import { Component } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { RxStompState } from '@stomp/rx-stomp';
import { RxStompService } from '../../../../../../services/rx-stomp.service';

@Component({
  selector: 'app-display-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
})
export class IndicatorComponent {
  indicator$: Observable<'OPEN' | 'CLOSED'> = this.rxStompService.connectionState$.pipe(
    filter(state => state === RxStompState.OPEN || state === RxStompState.CLOSED),
    map(state => (state === RxStompState.OPEN ? 'OPEN' : 'CLOSED')),
  );
  constructor(private rxStompService: RxStompService) {}
}
