<div class="header-div">
  <div mat-dialog-title class="dialog-title">
    <h2>Realizacija</h2>
    <button mat-icon-button mat-dialog-close="false" aria-label="Zatvori dijalog" matTooltip="Zatvori dijalog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ul>
    <li>ID plan instance: {{ data.data.realizationHeader.id }}</li>
    <li>Kategorija voza: {{ data.data.realizationHeader.train.trainCategory.name }}</li>
    <li>Broj voza: {{ data.data.realizationHeader.train.id }}</li>
    <li>Naziv rute: {{ data.data.realizationHeader.route.name }}</li>
  </ul>
</div>
<mat-tab-group>
  <mat-tab label="Detalji">
    <section class="table-container" tabindex="0">
      <table class="mb-3 table-style" mat-table [dataSource]="data.data.realizationDetailsItems">
        <div>
          <ng-container matColumnDef="ID" class="id-width">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <ng-container matColumnDef="STATION" class="id-width">
            <th mat-header-cell *matHeaderCellDef>Naziv stanice</th>
            <td mat-cell *matCellDef="let element">{{ element.station.name }}</td>
          </ng-container>

          <ng-container matColumnDef="KOLOSEK">
            <th mat-header-cell *matHeaderCellDef>Kolosek</th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.track !== element.plannedTrack">{{ element.plannedTrack }}->{{ element.track }}</div>
              <div *ngIf="element.track === element.plannedTrack">{{ element.track }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="TRANZIT">
            <th mat-header-cell *matHeaderCellDef>Tranzit</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon
                class="transit"
                svgIcon="yes"
                *ngIf="element.transit"
                matTooltip="Tranzit"
              ></mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="TIME_ARRIVAL">
            <th mat-header-cell *matHeaderCellDef>Planiran dolazak</th>
            <td mat-cell *matCellDef="let element">{{ element?.planTimeArrival | date : 'HH:mm dd.MM.yyyy' }}</td>
          </ng-container>

          <ng-container matColumnDef="TIME_DEPARTURE">
            <th mat-header-cell *matHeaderCellDef>Planiran polazak</th>
            <td mat-cell *matCellDef="let element">{{ element?.planTimeDeparture | date : 'HH:mm dd.MM.yyyy' }}</td>
          </ng-container>

          <ng-container matColumnDef="ARRIVAL_DELAY">
            <th mat-header-cell *matHeaderCellDef>Kašnjenje<br />u dolasku</th>
            <td mat-cell *matCellDef="let element">{{ element?.arrivalDelay }}</td>
          </ng-container>

          <ng-container matColumnDef="DEPARTURE_DELAY">
            <th mat-header-cell *matHeaderCellDef>Kašnjenje<br />u polasku</th>
            <td mat-cell *matCellDef="let element">{{ element?.departureDelay }}</td>
          </ng-container>

          <ng-container matColumnDef="STATUS">
            <th mat-header-cell *matHeaderCellDef>Status realizacije</th>
            <td mat-cell *matCellDef="let element">{{ element.realizationItemStatus }}</td>
          </ng-container>
        </div>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </section>
  </mat-tab>
  <mat-tab label="Istorija akcija">
    <ng-template matTabContent>
      <app-realisation-action-history [planInstanceId]="id"></app-realisation-action-history>
    </ng-template>
  </mat-tab>
</mat-tab-group>
