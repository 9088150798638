<h1 mat-dialog-title>Generisanje korisničkih poruka</h1>
<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="dropdowns-div">
    <div class="stations">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Stanica</mat-label>
        <mat-select
          #stationsSelect
          [formControl]="station"
          [compareWith]="compareStations"
          [(value)]="selectedStation"
          (selectionChange)="storeSelectedStation()"
        >
          <mat-option *ngFor="let station of stations$ | async" [value]="station">{{ station.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="tracks">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Kolosek</mat-label>
        <mat-select #tracksSelect [formControl]="tracks" multiple>
          <mat-option *ngFor="let track of tracks$ | async" [value]="track.trackNumber">
            {{ track.trackNumber }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="premisess">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Stanična prostorija</mat-label>
        <mat-select #premisesSelect [formControl]="premises" multiple>
          <mat-option *ngFor="let premise of premises$ | async" [value]="premise.id">{{ premise.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Prioritet</mat-label>
        <mat-select formControlName="messagePriority">
          <mat-option *ngFor="let priority of priorities | keyvalue" [value]="priority.value">{{
            getPriorityLabel(priority.value)
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="text-area">
      <mat-form-field class="textarea" appearance="outline">
        <mat-label>Poruka</mat-label>
        <textarea
          matInput
          formControlName="messageText"
          cdkTextareaAutosize
          cdkAutosizeMinRows="9"
          cdkAutosizeMaxRows="9"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="flex">
      <div class="date-div">
        <mat-form-field>
          <input
            matInput
            readonly
            [ngxMatDatetimePicker]="picker"
            placeholder="Datum i vreme"
            [formControl]="dateTimeControl"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #picker
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [color]="palette"
            [enableMeridian]="enableMeridian"
          ></ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
      <div class="immediately-checkbox m-3">
        <mat-checkbox [checked]="checkboxChecked" [color]="palette"> Odmah </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="submit-div">
    <button mat-flat-button mat-dialog-close>Odustani</button>
    <button [disabled]="!form.valid" mat-flat-button color="primary" type="submit">Generiši audio poruku</button>
  </div>
</form>
