export interface MenuItem {
  name: string;
  iconName: string;
  path: string;
}

export const ADMIN_ITEMS: MenuItem[] = [
  { name: 'Korisnici', iconName: 'users', path: '/main/users' },
  { name: 'Podešavanja', iconName: 'settings', path: '/main/settings' },
  { name: 'Audio kontroleri', iconName: 'audio-controller', path: '/main/audio-controller' },
];

export const CONTROLLER_ITEMS: MenuItem[] = [
  { name: 'Stanice', iconName: 'stations', path: '/main/stations' },
  { name: 'Rute', iconName: 'routes', path: '/main/routes' },
  { name: 'Vozovi', iconName: 'trains', path: '/main/trains' },
  { name: 'Operatori', iconName: 'operators', path: '/main/operators' },
  { name: 'Specijalni dani', iconName: 'special-days', path: '/main/special-days' },
  { name: 'Nedeljni rasporedi', iconName: 'weekly-schedules', path: '/main/weekly-schedules' },
  { name: 'Intervali plana', iconName: 'interval-plans', path: '/main/interval-plan' },
  { name: 'Planovi', iconName: 'plans', path: '/main/plans' },
  { name: 'Info table', iconName: 'info-table', path: '/main/display-track-realization' },
  { name: 'Status info tabli', iconName: 'info-table-monitor', path: '/main/info-table-monitor' },
  { name: 'Realizacija', iconName: 'realization', path: '/main/realization' },
  { name: 'Audio poruke', iconName: 'audio-messages', path: '/main/audio-messages' },
];

export const OPERATOR_ITEMS: MenuItem[] = [
  { name: 'Info table', iconName: 'info-table', path: '/main/display-track-realization' },
  { name: 'Status info tabli', iconName: 'info-table-monitor', path: '/main/info-table-monitor' },
  { name: 'Realizacija', iconName: 'realization', path: '/main/realization' },
  { name: 'Audio poruke', iconName: 'audio-messages', path: '/main/audio-messages' },
];
