<h2 mat-dialog-title>Obriši</h2>
<mat-dialog-content
  class="mat-typography"
  *ngIf="component !== 'plans' && component !== 'trains' && component !== 'routes' && component !== 'interval-plan' && component !== 'special-days' && component !== 'weekly-schedule'"
>
  <div>Da li ste sigurni da želite da obrišete ?</div>
</mat-dialog-content>
<mat-dialog-content class="mat-typography" *ngIf="component === 'trains'">
  <div>Da li ste sigurni da želite da obrišete voz?</div>
  <br />
  <div><strong>Voz ID:</strong> {{ data.data.id }}</div>
  <div><strong>Kategorija:</strong> {{ data.data.trainCategory.name }}</div>
</mat-dialog-content>
<mat-dialog-content class="mat-typography" *ngIf="component === 'plans'">
  <div>Da li ste sigurni da želite da obrišete plan?</div>
  <br />
  <div><strong>ID:</strong> {{ data.data.id }}</div>
  <div><strong>Interval:</strong> {{ data.data.planInterval.name }}</div>
  <div><strong>Ruta:</strong> {{ data.data.route.name }}</div>
  <div><strong>Voz ID:</strong> {{ data.data.trainId }}</div>
</mat-dialog-content>
<mat-dialog-content class="mat-typography" *ngIf="component === 'routes'">
  <div>Da li ste sigurni da želite da obrišete rutu?</div>
  <br />
  <div><strong>ID:</strong> {{ data.data.id }}</div>
  <div><strong>Naziv rute:</strong> {{ data.data.name }}</div>
</mat-dialog-content>
<mat-dialog-content class="mat-typography" *ngIf="component === 'special-days'">
  <div>Da li ste sigurni da želite da obrišete specijalni dan?</div>
  <br />
  <div><strong>ID:</strong> {{ data.data.id }}</div>
  <div><strong>Naziv:</strong> {{ data.data.name }}</div>
</mat-dialog-content>
<mat-dialog-content class="mat-typography" *ngIf="component === 'weekly-schedule'">
  <div>Da li ste sigurni da želite da obrišete nedeljni raspored?</div>
  <br />
  <div><strong>ID:</strong> {{ data.data.id }}</div>
  <div><strong>Naziv:</strong> {{ data.data.name }}</div>
</mat-dialog-content>
<mat-dialog-content class="mat-typography" *ngIf="component === 'interval-plan'">
  <div>Da li ste sigurni da želite da obrišete interval plana?</div>
  <br />
  <div><strong>ID:</strong> {{ data.data.id }}</div>
  <div><strong>Naziv:</strong> {{ data.data.name }}</div>
  <div><strong>Početni datum:</strong> {{ data.data.startDate | date: 'dd.MM.yyyy HH:mm:ss' }}</div>
  <div><strong>Krajnji datum:</strong> {{ data.data.endDate | date: 'dd.MM.yyyy HH:mm:ss' }}</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button type="button" mat-dialog-close>Otkaži</button>
  <button mat-flat-button color="primary" type="submit" (click)="deleteRow()">Obriši</button>
</mat-dialog-actions>
