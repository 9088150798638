<mat-dialog-content>
  <h1 mat-dialog-title>{{ 'Dodaj prostoriju za stanicu ' }} {{ data.name }}</h1>
  <form [formGroup]="premisesForm">
    <div class="row">
      <div class="col-lg-6 field-padding-bottom-0">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="label-mat">Naziv prostorije:</mat-label>
          <input matInput formControlName="promiseName" [errorStateMatcher]="matcher" />
        </mat-form-field>
        <mat-error
          *ngIf="sharedService.validationMessage('promiseName', premisesForm, isFormSubmitted)"
          name="promiseName"
        >
          <strong>{{ 'required' | translate }}</strong>
        </mat-error>
      </div>
      <div class="col-lg-6 field-padding-bottom-0">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="label-mat">Izaberi tip prostorije:</mat-label>
          <mat-select formControlName="selectedPromiseType" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let premiseType of premiseTypes | keyvalue: originalOrder" [value]="premiseType.key">{{
              premiseType.value
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error
          *ngIf="sharedService.validationMessage('selectedPromiseType', premisesForm, isFormSubmitted)"
          name="selectedPromiseType"
        >
          <strong>{{ 'required' | translate }}</strong>
        </mat-error>
      </div>
    </div>
    <div class="mt-3">
      <button
        mat-raised-button
        color="primary"
        (mousedown)="$event.preventDefault()"
        (click)="onClickAddPremiseStation()"
      >
        <img src="/assets/icons/add.svg" width="24px" alt="" /> Dodaj prostoriju
      </button>
    </div>

    <table mat-table [dataSource]="premises" class="my-3 table-style">
      <div>
        <ng-container matColumnDef="NAZIV PROSTORIJE">
          <th mat-header-cell *matHeaderCellDef><strong>NAZIV PROSTORIJE</strong></th>
          <td mat-cell *matCellDef="let p; index as i">
            {{ p.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="TIP PROSTORIJE">
          <th mat-header-cell *matHeaderCellDef><strong>TIP PROSTORIJE</strong></th>
          <td mat-cell *matCellDef="let p; index as i">
            {{ returnPremiseType(p.premiseType) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="AKCIJE">
          <th mat-header-cell *matHeaderCellDef><strong>AKCIJE</strong></th>
          <td mat-cell *matCellDef="let p; index as i">
            <button mat-icon-button (click)="removePremiseStation(p.id)">
              <mat-icon svgIcon="delete" class="delete" matTooltip="Obriši"></mat-icon>
            </button>
          </td>
        </ng-container>
      </div>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button (click)="closeModal()">Otkaži</button>
</mat-dialog-actions>
