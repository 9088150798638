import { Component, Input, OnInit } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Action } from '../../../../utils/enums';
import { Station } from '../../../../models/stations.model';
import { ActionsService } from '../../../../services/actions.service';
import { StartPostponeActionComponent } from '../realization/start-postpone-action/start-postpone-action.component';
import { ResetCancelActionComponent } from '../realization/reset-cancel-action/reset-cancel-action.component';
import { ArrivalsDeparturesComponent } from '../realization/arrivals-departures/arrivals-departures.component';
import { ChangeTrackComponent } from '../realization/change-track/change-track.component';
import { RouteShorteningComponent } from '../realization/route-shortening/route-shortening.component';
import { NotificationsComponent } from '../realization/notifications/notifications.component';
import { translationActions } from '../../../../utils/constants';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit {
  @Input() planInstanceId: number;
  @Input() station: Station;
  actions$: Observable<Action[]>;
  constructor(private actionService: ActionsService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.actions$ = this.actionService.planInstanceActionsForStation(this.planInstanceId, this.station.id);
  }

  getTranslation(action: Action): string {
    return translationActions[action];
  }

  openActionDialog(action: Action, id: number) {
    let dialogComponent: ComponentType<
      | StartPostponeActionComponent
      | ResetCancelActionComponent
      | ArrivalsDeparturesComponent
      | ChangeTrackComponent
      | RouteShorteningComponent
      | NotificationsComponent
    >;
    switch (action) {
      case Action.START:
      case Action.POSTPONE:
        dialogComponent = StartPostponeActionComponent;
        break;
      case Action.RESET:
      case Action.CANCEL:
        dialogComponent = ResetCancelActionComponent;
        break;
      case Action.ARRIVAL:
      case Action.DEPARTURE:
      case Action.CHANGE_ARRIVAL_DELAY:
      case Action.CHANGE_DEPARTURE_DELAY:
        dialogComponent = ArrivalsDeparturesComponent;
        break;
      case Action.CHANGE_TRACK:
        dialogComponent = ChangeTrackComponent;
        break;
      case Action.SHORTENING:
        dialogComponent = RouteShorteningComponent;
        break;
      case Action.NOTIFICATION:
        dialogComponent = NotificationsComponent;
        break;
    }

    if (dialogComponent) {
      this.dialog.open(dialogComponent, {
        width: '680px',
        minHeight: 'auto',
        panelClass: 'custom-dialog-container',
        data: {
          id: id,
          action: action,
          selectedStation: this.station,
        },
        autoFocus: false,
      });
    }
  }
}
