import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { map, merge, Observable, startWith, Subscription, switchMap } from 'rxjs';
import { CacheData } from '../../../../../models/cache.model';
import { MESSAGE } from '../../../../../utils/messages';
import { CacheService } from '../../../../../services/cache.service';
import { SharedService } from '../../../../../services/shared.service';
import { DeleteCacheComponent } from './delete/delete-cache.component';

@Component({
  selector: 'app-cache',
  templateUrl: './cache.component.html',
  styleUrls: ['./cache.component.scss'],
})
export class CacheComponent implements OnInit, AfterViewInit, OnDestroy {
  data: CacheData[];
  displayedColumns: string[] = ['NAME', 'SIZE', 'SIZE_IN_BYTES', 'ACTION'];
  private subscription: Subscription = new Subscription();
  constructor(private service: CacheService, private sharedService: SharedService, private dialog: MatDialog) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.subscription.add(
      merge(this.sharedService.gridReload$)
        .pipe(
          startWith({}),
          switchMap(() => this.service.get()),
          map(data => {
            if (data === null) {
              return [];
            }
            return data;
          }),
        )
        .subscribe(data => (this.data = data)),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  delete(cacheItem?: CacheData) {
    const config: MatDialogConfig = {
      width: '290px',
      height: 'auto',
      position: { top: '15%', left: '42%' },
    };
    if (cacheItem) {
      config['data'] = { name: cacheItem.name };
    }
    const dialog = this.dialog.open(DeleteCacheComponent, config);

    this._delete(dialog, cacheItem ? this.service.delete(cacheItem!.name) : this.service.clear());
  }

  private _delete(dialog: MatDialogRef<DeleteCacheComponent>, fnc: Observable<void>) {
    this.subscription.add(
      dialog.componentInstance.deleteEvent
        .asObservable()
        .pipe(switchMap(() => fnc))
        .subscribe({
          next: () => {
            this.sharedService.reloadGrid();
            this.sharedService.showSuccessMessage(MESSAGE.success_cache_clear);
            dialog.close();
          },
          error: (error: any) =>
            this.sharedService.showMessage(error.appCode ? error.appCode : error.error_cache_clear),
        }),
    );
  }
}
