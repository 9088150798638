import { Component } from '@angular/core';
import { map, share, timer } from 'rxjs';

@Component({
  selector: 'app-display-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  clock$ = timer(0, 1000).pipe(
    map(() => new Date()),
    share(),
  );
}
