import { ActionsObject } from '../models/realization.model';

export const SELECTED_STATION_KEY = 'selected_station';
export const ARRIVALS_DISPLAY = 'arrivals-display';
export const DEPARTURES_DISPLAY = 'departures-display';
export const DEPARTURES_ARRIVALS_DISPLAY = 'departures-arrivals-display';
export const TRACK_DISPLAY = 'track-display';
export const translationActions: ActionsObject = {
  START: 'Postavljanje na prvu stanicu',
  POSTPONE: 'Kašnjenje u pol. sa prve stanice',
  CANCEL: 'Otkazivanje',
  NOTIFICATION: 'Notifikacije',
  CHANGE_TRACK: 'Promena koloseka',
  ARRIVAL: 'Dolazak u stanicu',
  DEPARTURE: 'Polazak iz stanice',
  CHANGE_ARRIVAL_DELAY: 'Promena kašnjenja dolaska',
  CHANGE_DEPARTURE_DELAY: 'Promena kašnjenja polaska',
  RESET: 'Resetovanje statusa',
  SHORTENING: 'Skraćenje rute',
};
export const PING_MIN = 900000;
export const PING_MAX = 1800000;
