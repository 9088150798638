import { Component, OnDestroy, OnInit } from '@angular/core';
import { RxStompState } from '@stomp/rx-stomp';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  interval,
  map,
  Observable,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { PING_MAX, PING_MIN } from '../../../../../../utils/constants';
import { ShowElement } from '../../../../../../utils/enums';
import { MESSAGE } from '../../../../../../utils/messages';
import { StationArrivalDeparturesResponse } from '../../../../../../models/realization.model';
import { InfoTableService } from '../../../../../../services/info-table.service';
import { RxStompService } from '../../../../../../services/rx-stomp.service';
import { ActionsService } from '../../../../../../services/actions.service';
import { TranslateService } from '../../../../../../services/translate.service';

@Component({
  selector: 'app-arrival-departure',
  templateUrl: './arrival-departure.component.html',
  styleUrls: ['./arrival-departure.component.scss'],
  providers: [InfoTableService],
})
export class ArrivalDepartureComponent implements OnInit, OnDestroy {
  trackStatus = ShowElement;
  data: StationArrivalDeparturesResponse;
  private size: number = 10;
  private initData = false;
  private subscription: Subscription = new Subscription();

  private reconnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  get reconnected$(): Observable<boolean> {
    return this.reconnected.asObservable();
  }

  private getInitialData$: Observable<StationArrivalDeparturesResponse> = combineLatest([
    this.infoTableService.infoTableParams$,
    this.reconnected$,
  ]).pipe(
    switchMap(([params]) =>
      this.actionService.getArrivalDepartureRealizations(params.stationId, params.type, this.size),
    ),
  );

  watchStationTrack$: Observable<StationArrivalDeparturesResponse> = this.infoTableService.infoTableParams$.pipe(
    switchMap(params =>
      this.rxStompService.watch(`/s${params.stationId}/ad`).pipe(
        map(response => JSON.parse(response.body)),
        tap(() => this.infoTableService.ack(params)),
      ),
    ),
  );

  infoTableElement$ = this.infoTableService.infoTableElement$.pipe(distinctUntilChanged());

  constructor(
    private infoTableService: InfoTableService,
    private actionService: ActionsService,
    private rxStompService: RxStompService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([interval(this.randomDelay(PING_MIN, PING_MAX)), this.rxStompService.connectionState$])
        .pipe(
          filter(([, state]) => state === RxStompState.OPEN),
          switchMap(() => this.infoTableService.infoTableParams$),
        )
        .subscribe(params => {
          this.infoTableService.ping(params);
        }),
    );

    this.subscription.add(
      this.rxStompService.connectionState$
        .pipe(filter(state => state === RxStompState.OPEN || state === RxStompState.CLOSED))
        .subscribe(state => {
          switch (state) {
            case RxStompState.OPEN:
              if (this.initData) {
                this.reconnected.next(true);
              }
              break;
            case RxStompState.CLOSED:
              this.infoTableService.setInfoTableElement(ShowElement.OUT_OF_ORDER);
              break;
          }
        }),
    );

    this.subscription.add(
      this.getInitialData$.subscribe({
        next: res => {
          this.initData = true;
          this.data = res;
          this.infoTableService.setInfoTableElement(ShowElement.DISPLAY);
        },
        error: error => {
          this.infoTableService.setInfoTableElement(ShowElement.OUT_OF_ORDER);
          if (error && error.errorMessages) {
            error.errorMessages.forEach((err: any) => {
              console.log(
                err ? this.translateService.instant(err) : this.translateService.instant(MESSAGE.RUNTIME_ERROR),
              );
            });
          }
        },
      }),
    );

    this.subscription.add(
      this.watchStationTrack$.subscribe({
        next: res => {
          this.data = res;
          this.infoTableService.setInfoTableElement(ShowElement.DISPLAY);
        },
        error: () => {
          this.infoTableService.setInfoTableElement(ShowElement.OUT_OF_ORDER);
        },
      }),
    );
  }

  protected randomDelay(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
