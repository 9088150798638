<div class="main-div">
  <h1 class="main-header-text">Planovi</h1>
  <!---Table--->
  <div class="flex table-top-area">
    <div>
      <app-search></app-search>
    </div>
    <div class="add-station-div">
      <input (change)="onFilechange($event)" type="file" id="formFile" style="display: none" #fileUpload />
      <button mat-icon-button disableRipple class="btn-upload-plan" (click)="fileUpload.click()">
        <mat-icon svgIcon="upload" class="upload-icon" matTooltip="Učitaj plan"></mat-icon>
      </button>

      <button mat-icon-button disableRipple (click)="openDialog()" class="ml-3">
        <mat-icon svgIcon="add" class="add" matTooltip="Kreiraj plan"></mat-icon>
      </button>
    </div>
  </div>

  <!---Table--->
  <table class="table-style" mat-table [dataSource]="data" matSort>
    <ng-container matColumnDef="ID_PLAN">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="NAME_INT_PLAN">
      <th mat-header-cell *matHeaderCellDef>Naziv interval</th>
      <td mat-cell *matCellDef="let element">{{ element.planInterval.name }}</td>
    </ng-container>

    <ng-container matColumnDef="NAME_WEEK">
      <th mat-header-cell *matHeaderCellDef>Nedeljni raspored</th>
      <td mat-cell *matCellDef="let element">{{ element?.weekSchedule?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="ROUTE">
      <th mat-header-cell *matHeaderCellDef>Naziv rute</th>
      <td mat-cell *matCellDef="let element">{{ element.route.name }}</td>
    </ng-container>
    <ng-container matColumnDef="TRAIN_ID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="trainId" id="train_id">ID voza</th>
      <td mat-cell *matCellDef="let element">{{ element.trainId }}</td>
    </ng-container>
    <ng-container matColumnDef="DATE">
      <th mat-header-cell *matHeaderCellDef style="width: 168px">Datum ograničenja</th>
      <td mat-cell *matCellDef="let element">{{ element.cutDate | date : 'dd.MM.yyyy.' }}</td>
    </ng-container>
    <ng-container matColumnDef="ACTIVE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="active" id="active_id">Aktiviran</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.active" svgIcon="yes" class="checkbox-active"></mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="ACTION">
      <th mat-header-cell *matHeaderCellDef class="p-0">Akcije</th>
      <td mat-cell *matCellDef="let element" class="cell-size-actions">
        <div class="cell-actions">
          <button mat-icon-button (click)="openDetail(element.id)">
            <mat-icon svgIcon="details" class="details" matTooltip="Detalji plana"></mat-icon>
          </button>
          <button mat-icon-button (click)="openDuplicateModal(element)">
            <mat-icon svgIcon="copy" class="copy" matTooltip="Dupliranje plana"></mat-icon>
          </button>
          <button mat-icon-button (click)="openCutModal(element)" [disabled]="!isCutEnabled(element)">
            <mat-icon svgIcon="calendar" class="cut" matTooltip="Ograničenje plana"></mat-icon>
          </button>
          <button mat-icon-button [disabled]="element.active" (click)="putData(element.id)">
            <mat-icon svgIcon="edit" class="edit" matTooltip="Izmena plana"></mat-icon>
          </button>
          <button mat-icon-button (click)="openActivate(element.id)" [disabled]="!isActivationEnabled(element)">
            <mat-icon svgIcon="yes" class="activate" [matTooltip]="element.active ? 'Plan aktiviran' : 'Aktivacija plana'"></mat-icon>
          </button>
          <button mat-icon-button [disabled]="element.active" (click)="deleteDialog(element)">
            <mat-icon svgIcon="delete" class="delete" matTooltip="Brisanje plana"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="paginator">
    <mat-paginator [length]="total" [pageSize]="10" [hidePageSize]="true" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>
