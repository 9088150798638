<h1 mat-dialog-title>{{ data ? 'Izmeni interval plana #' + data.id + '' : 'Dodaj interval plana' }}</h1>
<form [formGroup]="intervalForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label class="label-mat">Interval:</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="startDate" placeholder="Start date" [errorStateMatcher]="matcher" />
        <input matEndDate formControlName="endDate" placeholder="End date" [errorStateMatcher]="matcher" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-error *ngIf="sharedService.validationMessage('startDate', intervalForm, isFormSubmitted)">
      <strong>{{ 'required' | translate }}</strong>
    </mat-error>

    <mat-form-field class="w-100 name-field" appearance="outline">
      <mat-label class="label-mat">Naziv:</mat-label>
      <input matInput appTrim formControlName="name" [errorStateMatcher]="matcher" />
    </mat-form-field>
    <mat-error *ngIf="sharedService.validationMessage('name', intervalForm, isFormSubmitted)">
      <strong>{{ 'required' | translate }}</strong>
    </mat-error>

    <mat-form-field class="w-100 desc-field" appearance="outline">
      <mat-label class="label-mat">Opis:</mat-label>
      <textarea matInput formControlName="description" appTrim [errorStateMatcher]="matcher"></textarea>
    </mat-form-field>
    <mat-error *ngIf="sharedService.validationMessage('description', intervalForm, isFormSubmitted)">
      <strong>{{ 'required' | translate }}</strong>
    </mat-error>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button type="button" (click)="closeModal()">Otkaži</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="disabled$ | async">Snimi</button>
  </mat-dialog-actions>
</form>
