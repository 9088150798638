<h1 mat-dialog-title>
  {{ data ? 'Izmeni nedeljni raspored (id: '+data.id+')' : 'Dodaj nedeljni raspored' }}
</h1>
<form [formGroup]="reportForm" (ngSubmit)="onSubmit()" >
  <mat-dialog-content>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label class="label-mat">Naziv:</mat-label>
      <input matInput appTrim formControlName="name" [errorStateMatcher]="matcher" />
    </mat-form-field>
    <mat-error *ngIf="sharedService.validationMessage('name', reportForm, isFormSubmitted)">
      <strong>{{ 'required' | translate }}</strong>
    </mat-error>

    <div class="mb-3">
      <mat-checkbox color="primary" class="checkbox" formControlName="monday">Ponedeljak</mat-checkbox>
    </div>
    <div class="mb-3">
      <mat-checkbox color="primary" class="checkbox" formControlName="tuesday">Utorak</mat-checkbox>
    </div>
    <div class="mb-3">
      <mat-checkbox color="primary" class="checkbox" formControlName="wednesday">Sreda</mat-checkbox>
    </div>
    <div class="mb-3">
      <mat-checkbox color="primary" class="checkbox" formControlName="thursday">Četvrtak</mat-checkbox>
    </div>
    <div class="mb-3">
      <mat-checkbox color="primary" class="checkbox" formControlName="friday">Petak</mat-checkbox>
    </div>
    <div class="mb-3">
      <mat-checkbox color="primary" class="checkbox" formControlName="saturday">Subota</mat-checkbox>
    </div>
    <div class="mb-3">
      <mat-checkbox color="primary" class="checkbox" formControlName="sunday">Nedelja</mat-checkbox>
    </div>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label class="label-mat">Specijalni dan:</mat-label>
      <mat-select formControlName="specialDayIndicator" [errorStateMatcher]="matcher">
        <mat-option *ngFor="let specialDay of specialDaysArray" [value]="specialDay.key">{{
          specialDay.value
          }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-error *ngIf="sharedService.validationMessage('specialDayIndicator', reportForm, isFormSubmitted)">
      <strong>{{ 'required' | translate }}</strong>
    </mat-error>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button type="button" (click)="closeModal()">Otkaži</button>
    <button mat-flat-button color="primary" type = "submit"  [disabled]="disabled$ | async">Snimi</button>
  </mat-dialog-actions>
</form>
