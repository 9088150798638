<div class="main-div">
  <h1 class="main-header-text">Status Info tabli</h1>
  <!---Table--->
  <div class="flex table-top-area">
    <div>
      <mat-form-field appearance="outline" class="bottom-0">
        <mat-label class="label-mat">Stanica</mat-label>
        <mat-select
          [compareWith]="compareStations"
          [(value)]="selectedStation"
          (selectionChange)="storeSelectedStation()"
          id="station_id"
        >
          <mat-option *ngFor="let station of stations$ | async" [value]="station"> {{ station.name }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <table class="table-style" mat-table [dataSource]="data" matSort>
    <ng-container matColumnDef="ID" class="id-width">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>
<!--    <ng-container matColumnDef="STATION">-->
<!--      <th mat-header-cell *matHeaderCellDef>STANICA</th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        {{ element.station.name }}-->
<!--      </td>-->
<!--    </ng-container>-->
    <ng-container matColumnDef="TYPE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="infoTableType">Tip</th>
      <td mat-cell *matCellDef="let element">
        <ng-container [ngSwitch]="element.infoTableType">
          <span *ngSwitchCase="infoTableType.TRACK">Kolosečna</span>
          <span *ngSwitchCase="infoTableType.ARRIVAL_DEPARTURE">Kombinovana</span>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="NAME">
      <th mat-header-cell *matHeaderCellDef>Naziv</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="IP">
      <th mat-header-cell *matHeaderCellDef>IP adresa</th>
      <td mat-cell *matCellDef="let element">
        {{ element.ipAddress }}
      </td>
    </ng-container>
    <ng-container matColumnDef="TRACK">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="track">Kolosek</th>
      <td mat-cell *matCellDef="let element">
        {{ element.track }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ACK">
      <th mat-header-cell *matHeaderCellDef>Datum potvrde</th>
      <td mat-cell *matCellDef="let element">
        {{ element.acknowledgeDate | date : 'HH:mm:ss' }}
        <br />
        {{ element.acknowledgeDate | date : 'dd.MM.yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="PING">
      <th mat-header-cell *matHeaderCellDef>Ping datum</th>
      <td mat-cell *matCellDef="let element">
        {{ element.pingDate | date : 'HH:mm:ss' }}
        <br />
        {{ element.pingDate | date : 'dd.MM.yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="STATUS">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <ng-container [ngSwitch]="element.infoTableStatus">
          <mat-icon
            svgIcon="warning"
            class="warning"
            matTooltip="Potrebna provera"
            matTooltipPosition="above"
            *ngSwitchCase="infoTableStatus.NOK"
          ></mat-icon>
          <mat-icon
            svgIcon="yes-action"
            class="yes-action"
            matTooltip="Displej aktivan"
            matTooltipPosition="above"
            *ngSwitchCase="infoTableStatus.OK"
          ></mat-icon>
        </ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="paginator">
    <mat-paginator [length]="total" [pageSize]="pageSize" [hidePageSize]="true" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>
