<div class="main-div">
  <!---Table--->
  <div class="table-top-area justify-content-end">
    <button mat-icon-button (click)="delete()">
      <mat-icon svgIcon="delete" class="delete all" matTooltip="Obriši sve"></mat-icon>
    </button>
  </div>
  <!---Table--->
  <table class="table-style" mat-table [dataSource]="data">
    <ng-container matColumnDef="NAME" class="id-width">
      <th mat-header-cell *matHeaderCellDef>Naziv</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="SIZE">
      <th mat-header-cell *matHeaderCellDef>Broj</th>
      <td mat-cell *matCellDef="let element">{{ element.size }}</td>
    </ng-container>

    <ng-container matColumnDef="SIZE_IN_BYTES">
      <th mat-header-cell *matHeaderCellDef>Veličina</th>
      <td mat-cell *matCellDef="let element">{{ element.sizeFormatted }}</td>
    </ng-container>

    <ng-container matColumnDef="ACTION">
      <th mat-header-cell *matHeaderCellDef class="p-0">Akcije</th>
      <td mat-cell *matCellDef="let element" class="cell-size-actions">
        <div class="cell-actions">
          <button mat-icon-button (click)="delete(element)">
            <mat-icon svgIcon="delete" class="delete" matTooltip="Obriši"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
