<h1 mat-dialog-title>{{ data ? 'Izmeni rutu (id #' + data.id + ')' : 'Dodaj rutu' }}</h1>
<form [formGroup]="routeForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-lg-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Ime rute:</mat-label>
        <input matInput formControlName="name" name="name" required />
        <mat-error>
          <strong>{{ 'required' | translate }}</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Izaberi stanicu:</mat-label>
        <mat-select formControlName="stationId" [value]="matSelectValue(stations)">
          <mat-option *ngFor="let station of stations" [value]="station">
            {{ station.name ?? station.stationNext?.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <strong>{{ 'required' | translate }}</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <button
    mat-flat-button
    type="button"
    color="primary"
    (mousedown)="$event.preventDefault()"
    (click)="addToArray()"
    [ngStyle]="{ color: '#ffffff', background: disableBtn ? '#6E6F72' : null }"
  >
    <img
      class="icon"
      src="../../../../../../assets/icons/add.svg"
      alt=""
      [ngStyle]="{ transform: disableBtn ? 'rotate(45deg)' : '' }"
    />
    {{ disableBtn ? 'Nema susedne stanice' : 'Dodaj stanicu na rutu' }}
  </button>

  <mat-dialog-content class="table-div">
    <table mat-table [dataSource]="this._routeItems.getValue()" class="my-3 table-style">
      <!-- Name Column -->
      <ng-container matColumnDef="NAZIV STANICE">
        <th mat-header-cell *matHeaderCellDef><strong>NAZIV STANICE</strong></th>
        <td mat-cell *matCellDef="let content">
          <input class="station-input" [value]="returnContentStationName(content)" readonly />
        </td>
      </ng-container>

      <!-- Via Column -->
      <ng-container matColumnDef="PREKO">
        <th mat-header-cell *matHeaderCellDef><strong>PREKO</strong></th>
        <td mat-cell *matCellDef="let content; index as i">
          <div class="btns-delete-check">
            <div class="via-div">
              <mat-checkbox color="primary" [checked]="viaValue(i)" (change)="toggleVia(content)"> </mat-checkbox>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="AKCIJE">
        <th mat-header-cell *matHeaderCellDef><strong>AKCIJE</strong></th>
        <td mat-cell *matCellDef="let content; index as i">
          <button *ngIf="isLastInArray(content, lastInArray)" mat-icon-button (click)="removeStation(i)">
            <mat-icon svgIcon="delete" class="delete" matTooltip="Obriši"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div #scrollMe></div>
  </mat-dialog-content>

  <mat-dialog-actions class="d-dlex justify-content-between">
    <button mat-stroked-button color="primary" type="button" (click)="reset()">Resetuj stanice</button>
    <div>
      <button mat-flat-button type="button" (click)="closeModal()">Otkaži</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="this.getDisabled()">Snimi</button>
    </div>
  </mat-dialog-actions>
</form>
