<div class="container-fluid px-0 d-flex h-100" [class.closed]="toggleSideBar$ | async">
  <div class="sidenav">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main">
    <app-navbar></app-navbar>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div class="footer">
      <ng-container *ngIf="version$ | async as version">{{ version?.version }} (build {{ version?.buildTime | date: 'YYYY-dd-MM HH:mm:ss' }})</ng-container> Copyright © Enetel Solutons
      2023
    </div>
  </div>
</div>
