<h1 mat-dialog-title>{{ 'Rola za korisnika ' }} {{ data.content.username }}</h1>
<mat-dialog-content>
  <div class="d-flex align-items-center justify-content-between mx-4 my-5">
    <mat-checkbox
      *ngFor="let role of roles | keyvalue"
      [checked]="isRole(role.value)"
      (change)="onChecked($event, role.value)"
      labelPosition="before"
      class="checkbox-special-day"
      color="primary"
      >{{ getUserRole(role.value) }}
    </mat-checkbox>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button type="button" (click)="closeModal()">Otkaži</button>
  <button mat-flat-button color="primary" type="submit" (click)="onSubmit()">Snimi</button>
</mat-dialog-actions>
