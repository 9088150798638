import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const trackOrPremisesSelectedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const tracks = control.get('tracks');
  const premises = control.get('premiseIds');

  return !tracks?.value.length && !premises?.value.length ? { notSelected: true } : null;
};

export const checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
  const pass = group.get('password')?.value;
  const confirmPass = group.get('passwordRepeat')?.value;
  return pass === confirmPass ? null : { notSame: true };
};
