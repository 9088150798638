<h1 mat-dialog-title>{{ 'Susedna stanica za stanicu ' }} {{ data.name }}</h1>
<mat-dialog-content>
  <form [formGroup]="subsequentStationForm">
    <div class="field-padding-bottom-0">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Izaberi susednu stanicu:</mat-label>
        <mat-select formControlName="selectedStation">
          <mat-option *ngFor="let station of allStations$ | async" [value]="station">
            {{ station.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <strong>{{ 'required' | translate }}</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="mt-3">
      <button mat-raised-button color="primary" (mousedown)="$event.preventDefault()" (click)="onClickAddSubStation()">
        <span class="material-symbols-outlined add">
          <img src="/assets/icons/add.svg" class="icons" alt="" />
        </span>
        Dodaj izabranu susednu stanicu
      </button>
    </div>
    <table mat-table [dataSource]="subsequentStations" class="my-3 table-style">
      <div>
        <ng-container matColumnDef="NAZIV SUSEDNE STANICE">
          <th mat-header-cell *matHeaderCellDef>NAZIV SUSEDNE STANICE</th>
          <td mat-cell *matCellDef="let station; index as i">
            {{ station.stationNext.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="AKCIJE">
          <th mat-header-cell *matHeaderCellDef class="p-0">AKCIJA</th>
          <td mat-cell *matCellDef="let station; index as i">
            <button mat-icon-button (click)="removeSubsequentStation(station.id)">
              <mat-icon svgIcon="delete" class="delete" matTooltip="Obriši"></mat-icon>
            </button>
          </td>
        </ng-container>
      </div>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button (click)="closeModal()" type="button">Otkaži</button>
</mat-dialog-actions>
