import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { StationTrackRealizationResponse } from '../models/display-realization.model';
import {
  ActionHistory,
  RealizationDetails,
  StationActionData,
  StationActionResponse,
  StationRealizationItem,
} from '../models/realization.model';
import { Action } from '../utils/enums';
import { IPage } from '../models/shared.model';
import { formatDateCustom } from '../utils/date-time';

@Injectable({
  providedIn: 'root',
})
export class RealizationService {
  constructor(private http: HttpClient) {}

  //Get search, pagination and sort
  search(
    stationId: number,
    allStatuses: boolean,
    pageNumber: number,
    pageSize: number,
    searchKey?: string,
    realisationDate?: Date,
  ): Observable<IPage<StationRealizationItem>> {
    let paramsObject: { [k: string]: any } = {
      stationId: stationId,
      allStatuses: allStatuses,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    if (searchKey) {
      paramsObject['searchKey'] = searchKey;
    }
    if (realisationDate) {
      paramsObject['date'] = formatDateCustom(realisationDate);
    }
    let params = new HttpParams({ fromObject: paramsObject });

    return this.http.get<IPage<StationRealizationItem>>('realizations/station-realizations', {
      params,
    });
  }

  get(stationId: number, track: number): Observable<StationTrackRealizationResponse> {
    return this.http.get<StationTrackRealizationResponse>('realizations/station-track-realization', {
      params: { 'station-id': stationId, track },
    });
  }

  getRealizationDetails(realizationId: number): Observable<RealizationDetails> {
    let params = new HttpParams().set('realizationId', realizationId);
    return this.http.get<RealizationDetails>('realizations/details', { params });
  }

  stationsForAction(stationId: number, realizationId: number, action: Action): Observable<StationActionData[]> {
    const params = new HttpParams()
      .set('stationId', stationId.toString())
      .set('realizationId', realizationId.toString())
      .set('action', action);

    return this.http
      .get<StationActionResponse>('realizations/stations-for-action', { params })
      .pipe(map(response => response.stationActionDataList));
  }

  getActionHistory(id: number): Observable<ActionHistory[]> {
    let params = new HttpParams().set('realizationId', id);
    return this.http.get<ActionHistory[]>('realizations/action-history', { params });
  }
}
