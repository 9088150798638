import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class IconLoaderService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.registerIcons();
  }

  private registerIcons() {
    this.iconRegistry
      .addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/navbar.svg'))
      .addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/avis2-set.svg'))
      .addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/realisation-actions.svg'));
  }
}
