import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { map, merge, Observable, shareReplay, tap, switchMap, Subscription } from 'rxjs';
import { MyErrorStateMatcher } from 'src/app/directives/error-directive.directive';
import { ActionComponentData, StationActionData } from 'src/app/models/realization.model';
import { ActionsService } from 'src/app/services/actions.service';
import { RealizationService } from 'src/app/services/realization.service';
import { SharedService } from 'src/app/services/shared.service';
import { Action } from 'src/app/utils/enums';
import { MESSAGE } from 'src/app/utils/messages';

export enum ShorteningPosition {
  START = 'START',
  END = 'END',
}

@Component({
  selector: 'app-route-shortening',
  templateUrl: './route-shortening.component.html',
  styleUrls: ['./route-shortening.component.scss'],
})
export class RouteShorteningComponent implements AfterViewInit, OnDestroy {
  @ViewChild('start', { static: true }) start: MatSelect;
  @ViewChild('end', { static: true }) end: MatSelect;
  form: FormGroup = this.fb.group({
    startRoute: ['', Validators.required],
    endRoute: ['', Validators.required],
  });
  private _disabled = false;
  matcher = new MyErrorStateMatcher();
  shorteningPosition = ShorteningPosition;
  startSelected: StationActionData;
  endSelected: StationActionData;
  stations$: Observable<StationActionData[]> = this.realizationService
    .stationsForAction(this.data.selectedStation.id, this.data.id, Action.SHORTENING)
    .pipe(shareReplay(1));
  start$: Observable<StationActionData[]> = this.stations$.pipe(
    map(res => res.filter((item: StationActionData) => item.isShorteningFirstCandidate)),
    tap(res => {
      this.startSelected = res[0];
      this.form.get('startRoute')?.setValue(res[0]?.station.id);
    }),
  );
  end$: Observable<StationActionData[]> = this.stations$.pipe(
    map(res => res.filter((item: StationActionData) => item.isShorteningLastCandidate)),
    tap(res => {
      this.endSelected = res[res.length - 1];
      this.form.get('endRoute')?.setValue(res[res.length - 1]?.station.id);
    }),
  );
  subscription: Subscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ActionComponentData,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<RouteShorteningComponent>,
    private actionsServices: ActionsService,
    private realizationService: RealizationService,
  ) {}

  ngAfterViewInit(): void {
    this.subscription.add(
      merge(
        this.start.selectionChange.pipe(
          map(change => ({ position: this.shorteningPosition.START, value: change.value })),
        ),
        this.end.selectionChange.pipe(map(change => ({ position: this.shorteningPosition.END, value: change.value }))),
      )
        .pipe(
          switchMap((change: { position: ShorteningPosition; value: number }) =>
            this.stations$.pipe(
              map(stations => {
                const compareStation =
                  change.position === this.shorteningPosition.START ? this.endSelected : this.startSelected;
                const newStation = stations.find(station => station.station.id === change.value);
                const newStationIndex = stations.indexOf(newStation!);
                const compareStationIndex = stations.indexOf(compareStation);

                if (change.position === this.shorteningPosition.START) {
                  if (newStationIndex >= compareStationIndex) {
                    this.start.value = this.startSelected.station.id;
                    this.sharedService.openSnackBarError('Početna stanica ne može biti posle krajnje stanice!');
                  } else {
                    this.startSelected = newStation!;
                  }
                } else {
                  if (newStationIndex <= compareStationIndex) {
                    this.end.value = this.endSelected.station.id;
                    this.sharedService.openSnackBarError('Krajnja stanica ne može biti pre početne stanice!');
                  } else {
                    this.endSelected = newStation!;
                  }
                }
              }),
            ),
          ),
        )
        .subscribe(),
    );
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.disabled = true;
      const firstStation = this.form.get('startRoute')?.value;
      const secondStation = this.form.get('endRoute')?.value;
      this.subscription.add(
        this.actionsServices.shortening(this.data.id, firstStation, secondStation).subscribe({
          next: () => {
            this.disabled = false;
            this.closeModal();
            this.sharedService.reloadGrid();
            this.sharedService.openSnackBar(MESSAGE.success_shortening);
          },
          error: () => (this.disabled = false),
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
