import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, interval, Subscription, switchMap } from 'rxjs';
import { RxStompState } from '@stomp/rx-stomp';
import { environment } from '../../../../../../../environments/environment';
import { DepartureArrival } from '../../../../../../utils/enums';
import { InfoTableType } from '../../../../../../models/info-table';
import { StationArrivalDeparturesResponse, StationRealizationItem } from '../../../../../../models/realization.model';
import { DisplayStateData, DisplayStore } from '../../../../../../services/display.store';
import { RxStompService } from '../../../../../../services/rx-stomp.service';
import { BlinkDirective } from '../../../../../../directives/blink.directive';

@Component({
  selector: 'app-display-station',
  host: {
    '[class.arv]': 'isArrivalsDisplay',
  },
  templateUrl: './display-station.component.html',
  styleUrls: ['./display-station.component.scss'],
  providers: [DisplayStore],
})
export class DisplayStationComponent implements OnInit, OnDestroy {
  @ViewChildren(BlinkDirective, { emitDistinctChangesOnly: true }) blink!: QueryList<BlinkDirective>;
  @Input() set realization(response: StationArrivalDeparturesResponse) {
    this.setData(response);
    this.dataToDisplay(response);
    let items: StationRealizationItem[] = [];
    switch (response.infoTableType) {
      case InfoTableType.DEPARTURE:
        items = [...response.departures];
        break;
      case InfoTableType.ARRIVAL:
        items = [...response.arrivals];
        break;
      case InfoTableType.ARRIVAL_DEPARTURE:
        items = [...response.departures, ...response.arrivals];
        break;
    }
    const itemsMap = new Map<number, DisplayStateData>();
    items.map(item => {
      itemsMap.set(item.planInstanceItem.id, {
        plan: item.planInstanceItem.planInstanceId,
        delay: item.realizationItem.departureDelay ?? item.realizationItem.arrivalDelay ?? 0,
        track: item.realizationItem.track ?? item.planInstanceItem.track ?? 0,
        shortNotification: item.realizationItem.shortNotification,
      });
    });
    this.displayStore.setRealizationItems(itemsMap);
  }

  data: StationRealizationItem[];
  isArrivalsDisplay: boolean;
  notifications: string[];
  messageSwitchTime: number;
  url: string = environment.baseUrl;
  private type: InfoTableType;
  private blinkingSpeed: string = '1000ms';
  private blinkingCount: number = 10;
  private subscription: Subscription = new Subscription();

  private _switchTime: BehaviorSubject<number> = new BehaviorSubject<number>(30);
  get switchTime$() {
    return this._switchTime.asObservable();
  }
  private _dataMap = new Map<DepartureArrival, StationRealizationItem[]>();
  get dataMap(): ReadonlyMap<string, StationRealizationItem[]> {
    return this._dataMap;
  }
  private _notificationsMap = new Map<DepartureArrival, string[]>();
  get notificationsMap(): ReadonlyMap<string, string[]> {
    return this._notificationsMap;
  }

  switchDisplay$ = combineLatest([this.switchTime$, this.rxStompService.connectionState$]).pipe(
    filter(([, state]) => state === RxStompState.OPEN && this.type === InfoTableType.ARRIVAL_DEPARTURE),
    switchMap(([switchTime, ,]) => interval(switchTime * 1000)),
  );

  constructor(private readonly displayStore: DisplayStore, private rxStompService: RxStompService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.switchDisplay$.subscribe(() => {
        this.isArrivalsDisplay = !this.isArrivalsDisplay;
        this.data = (
          this.isArrivalsDisplay
            ? this.dataMap.get(DepartureArrival.ARRIVAL)
            : this.dataMap.get(DepartureArrival.DEPARTURE)
        )!;
        this.notifications = (
          this.isArrivalsDisplay
            ? this.notificationsMap.get(DepartureArrival.ARRIVAL)
            : this.notificationsMap.get(DepartureArrival.DEPARTURE)
        )!;
      }),
    );

    this.displayStore.blink$.subscribe(blink => {
      blink.realizationItems.forEach((value, key) => {
        if (value) {
          const bdd = this.blink.filter(item => item.blink === key);
          if (bdd.length) {
            if (value.plan || value.delay || value.track || value.shortNotification) {
              bdd.forEach(item => item.blinkItem(this.blinkingSpeed, this.blinkingCount));
            }
          }
        }
      });
    });
  }

  private dataToDisplay(res: StationArrivalDeparturesResponse) {
    this.blinkingSpeed = `${res.blinkingSpeed * 2}ms`;
    this.blinkingCount = Math.round(Number((res.blinkingTime * 1000) / res.blinkingSpeed / 2));

    switch (res.infoTableType) {
      case InfoTableType.ARRIVAL:
        this.data = res.arrivals;
        this.notifications = res.arrivalMessages;
        this.isArrivalsDisplay = true;
        break;
      case InfoTableType.DEPARTURE:
        this.data = res.departures;
        this.notifications = res.departureMessages;
        this.isArrivalsDisplay = false;
        break;
      case InfoTableType.ARRIVAL_DEPARTURE:
        this.data = this.isArrivalsDisplay ? res.arrivals : res.departures;
        this.notifications = this.isArrivalsDisplay ? res.arrivalMessages : res.departureMessages;
        if (res.switchTime !== this._switchTime.getValue()) {
          this._switchTime.next(res.switchTime);
        }
    }
  }

  private setData(res: StationArrivalDeparturesResponse) {
    this.type = res.infoTableType;
    this.messageSwitchTime = res.messageSwitchTime;
    this._dataMap.set(DepartureArrival.ARRIVAL, res.arrivals);
    this._dataMap.set(DepartureArrival.DEPARTURE, res.departures);
    this._notificationsMap.set(DepartureArrival.ARRIVAL, res.arrivalMessages);
    this._notificationsMap.set(DepartureArrival.DEPARTURE, res.departureMessages);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
