<div class="main-div">
  <h1 class="main-header-text">Audio kontroleri</h1>
  <div class="flex table-top-area">
    <div>
      <app-search></app-search>
    </div>
  </div>
  <table class="table-style" mat-table [dataSource]="data" matSort>
    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Naziv</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
<!--      <ng-container matColumnDef="API_URL">-->
<!--        <th mat-header-cell *matHeaderCellDef>API URL</th>-->
<!--        <td mat-cell *matCellDef="let element">-->
<!--          <a *ngIf="element.apiUrl" [href]="element.apiUrl" target="_blank">{{ element.apiUrl }}</a>-->
<!--        </td>-->
<!--      </ng-container>-->
    <ng-container matColumnDef="ENABLED">
      <th mat-header-cell *matHeaderCellDef>Aktiviran</th>
      <td class="cell-active" mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.enabled" svgIcon="yes" class="transit"></mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="ACTION">
      <th mat-header-cell *matHeaderCellDef class="p-0">Akcije</th>
      <td mat-cell *matCellDef="let element" class="cell-size-actions">
        <div class="cell-actions">
          <button mat-icon-button (click)="getById(element)">
            <mat-icon svgIcon="edit" class="edit" matTooltip="Ažuriraj"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="paginator">
    <mat-paginator
      [length]="total"
      [pageSize]="10"
      [hidePageSize]="true"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>
</div>
