<ng-container *ngIf="data?.stationRealizationItems?.length; else welcome">
  <app-display-header>
    <div class="track-info">
      <div class="label">kolosek <span>track</span></div>
      <div class="track-id">{{ data.track }}</div>
    </div>
  </app-display-header>
  <ng-container *ngFor="let item of data.stationRealizationItems">
    <div class="track" [class.multi]="data.stationRealizationItems.length > 1" [class.won]="!notifications.length" [blink]="item.planInstanceItem.id">
      <div class="info">
        <div class="operator">
          <img [src]="logoUrl(data.stationRealizationItems[0].train.operator.id!)" alt="" />
        </div>
        <div class="train-category blink">
          {{ item.train.trainCategory.code }}
        </div>
        <div class="train-id blink">
          <p>{{ item.train.id }}</p>
        </div>
        <div class="route blink">
          {{ item.routeHeader.name }}
        </div>
      </div>
      <div
        class="standard"
        [class.transit-station]="item.planInstanceItem.planTimeArrival && item.planInstanceItem.planTimeDeparture"
      >
        <div class="timing">
          <div *ngIf="item.planInstanceItem.planTimeArrival" class="arrival">
            <div class="time blink">{{ item.planInstanceItem.planTimeArrival | date : 'HH:mm' }}</div>
            <div class="label">dolazak <span>arrival</span></div>
          </div>
          <div *ngIf="item.planInstanceItem.planTimeDeparture" class="departure">
            <div class="time blink">{{ item.planInstanceItem.planTimeDeparture | date : 'HH:mm' }}</div>
            <div class="label">polazak <span>departure</span></div>
          </div>
          <div class="delay">
            <div class="time blink blinkDelay">
              {{ item.realizationItem.departureDelay ?? item.realizationItem.arrivalDelay ?? 0 }}
            </div>
            <div class="label">kašnjenje <span>delay</span></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="data.stationRealizationItems.length === 1 && notifications.length" class="notification-area">
    <div class="notification">
      <app-notifications [notifications]="notifications" [switchTime]="data?.messageSwitchTime!"></app-notifications>
    </div>
  </div>
</ng-container>
<ng-template #welcome>
  <!---Welcome section-->
  <app-welcome [station]="data?.station!"></app-welcome>
</ng-template>
