import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MainModule } from './admin/main/main.module';
import { ApiInterceptor } from './services/api.interceptor';
import { AppComponent } from './app.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { LogoutModalComponent } from './modals/logout-modal/logout-modal.component';
import { ChangePasswordModalComponent } from './modals/change-password-modal/change-password-modal.component';
import { myCustomTooltipDefaults } from './utils/my-custom-tooltips-defaults';
import { RxStompService } from './services/rx-stomp.service';
import { RxStompServiceFactory } from './services/rx-stomp-service-factory';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AppComponent, AuthenticationComponent, LogoutModalComponent, ChangePasswordModalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MainModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'sr-Latn' },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    DatePipe,
    {
      provide: RxStompService,
      useFactory: RxStompServiceFactory,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
