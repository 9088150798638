import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActionHistory } from '../../../../../../models/realization.model';
import { RealizationService } from '../../../../../../services/realization.service';
import { translationActions } from '../../../../../../utils/constants';

@Component({
  selector: 'app-realisation-action-history',
  templateUrl: './action-history.component.html',
  styleUrls: ['./action-history.component.scss'],
})
export class ActionHistoryComponent implements OnInit {
  @Input() planInstanceId: number;
  subscription: Subscription = new Subscription();
  data: ActionHistory[];
  displayedColumnsActionHistory: string[] = ['createdDate', 'action', 'station', 'delay', 'track', 'createdBy'];
  constructor(private realizationService: RealizationService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.realizationService.getActionHistory(this.planInstanceId).subscribe((actionHistoryData: ActionHistory[]) => {
        this.data = actionHistoryData;
      }),
    );
  }

  getActionTranslation(action: string): string {
    return translationActions[action];
  }
}
