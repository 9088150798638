<div class="main-div">
  <h1 class="main-header-text">Intervali plana</h1>
  <!---Table--->
  <div class="flex table-top-area">
    <div>
      <app-search></app-search>
    </div>
    <div class="add-station-div">
      <button mat-icon-button disableRipple (click)="openDialog()">
        <mat-icon svgIcon="add" class="add" matTooltip="Kreiraj interval plana"></mat-icon>
      </button>
    </div>
  </div>
  <!---Table--->
  <table class="table-style" mat-table [dataSource]="data" matSort>
    <ng-container matColumnDef="ID_INTERVAL_PLAN">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id" id="plan_id">ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="START_DATE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="startDate" id="start_date">Početni datum</th>
      <td mat-cell *matCellDef="let element">{{ element.startDate | date : 'dd.MM.yyyy HH:mm:ss' }}</td>
    </ng-container>

    <ng-container matColumnDef="END_DATE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="endDate" id="end_date">Krajnji datum</th>
      <td mat-cell *matCellDef="let element">
        {{ element.endDate | date : 'dd.MM.yyyy HH:mm:ss' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Naziv</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="DESC">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="description">Opis</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="ACTION">
      <th mat-header-cell *matHeaderCellDef class="p-0">Akcije</th>
      <td mat-cell *matCellDef="let element" class="cell-size-actions">
        <div class="cell-actions">
          <button mat-icon-button (click)="getById(element.id)">
            <mat-icon svgIcon="edit" class="edit" matTooltip="Ažuriraj"></mat-icon>
          </button>
          <button mat-icon-button (click)="deleteDialog(element)">
            <mat-icon svgIcon="delete" class="delete" matTooltip="Obriši"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="paginator">
    <mat-paginator [length]="total" [pageSize]="10" [hidePageSize]="true" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>
