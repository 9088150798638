import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { Components } from '../utils/enums';
import { Version } from '../models/shared.model';
import { TranslateService } from './translate.service';
import { NotificationSnackBarComponent } from '../admin/main/components/reusable-component/notification-snack-bar/notification-snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  timeOut: number = 10000;
  private _gridReload = new Subject<void>();

  constructor(private _snackBar: MatSnackBar, private http: HttpClient, private translateService: TranslateService) {}

  get gridReload$(): Observable<void> {
    return this._gridReload.asObservable();
  }

  reloadGrid() {
    this._gridReload.next();
  }

  openSnackBar(message: string) {
    return this._openSnackBar({
      data: {
        icon: 'info',
        message,
      },
      panelClass: ['blue-snackbar'],
    });
  }

  openSnackBarError(message: string) {
    return this._openSnackBar({
      data: {
        icon: 'error',
        message,
      },
      panelClass: ['red-snackbar'],
    });
  }

  openSnackBarWait(message: string, action: string) {
    return this._openSnackBar({
      data: {
        icon: 'info',
        message,
        action,
      },
      duration: 120000,
      panelClass: ['blue-snackbar'],
    });
  }

  //Snack bar validation
  openSnackBarValidation(message: string, action: string) {
    this._openSnackBar({
      data: {
        icon: 'error',
        message,
        action,
      },
      duration: this.timeOut,
      panelClass: ['red-snackbar-validate'],
    });
  }

  showSuccessMessage(message: string) {
    return this._openSnackBar({
      data: {
        icon: 'info',
        message: this.translateService.instant(message),
      },
      panelClass: ['blue-snackbar'],
    });
  }

  public showMessage(message: string) {
    return this._openSnackBar({
      data: {
        icon: 'error',
        message: this.translateService.instant(message),
      },
      panelClass: ['red-snackbar'],
    });
  }

  private _openSnackBar(config: MatSnackBarConfig) {
    return this._snackBar.openFromComponent(NotificationSnackBarComponent, config);
  }

  validationMessage(controlName: string, form: any, submit: boolean): boolean {
    let control = form.controls[controlName];
    return control && control.invalid && (control.touched || submit);
  }

  //Get copyright value
  getCopyrightMarker(): Observable<Version> {
    return this.http.get<Version>('version');
  }

  //is for edit shared method
  isForEdit(id: number, components: Components): Observable<void> {
    let route = '';
    if (components === Components.INTERVAL_PLAN) {
      route = 'plan-intervals/is-for-edit';
    } else if (components === Components.ROUTES) {
      route = 'routes/is-for-edit';
    } else if (components === Components.PLANS) {
      route = 'plan/is-for-edit';
    } else if (components === Components.WEEKLY_SCHEDULE) {
      route = 'week-schedule/is-for-edit';
    }
    return this.http.get<void>(`${route}/${id}`);
  }

  //is for delete shared method
  isForDelete(id: number, components: Components): Observable<void> {
    let route = '';
    if (components === Components.STATION) {
      route = 'stations/is-for-delete';
    } else if (components === Components.TRAINS) {
      route = 'trains/is-for-delete';
    }
    return this.http.get<void>(`${route}/${id}`);
  }
}
