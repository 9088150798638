<ng-container *ngIf="realizationDetails$ | async as realizationDetails">
  <div mat-dialog-title class="dialog-title">
    <h2>
      {{ title }} {{ realizationDetails.realizationHeader.train.id }}
      {{ realizationDetails.realizationHeader.route.name }}
    </h2>
    <button mat-icon-button mat-dialog-close="false" aria-label="Zatvori dijalog" matTooltip="Zatvori dijalog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ul>
    <li>ID plan instance: {{ realizationDetails.realizationHeader.id }}</li>
    <li>Kategorija voza: {{ realizationDetails.realizationHeader.train.trainCategory.name }}</li>
  </ul>
  <hr />
  <mat-dialog-content>
    <div>
      <div>
        <app-train-location [realizationDetailsItems]="realizationDetails.realizationDetailsItems"></app-train-location>
      </div>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button *ngIf="submitTitle" mat-flat-button color="primary" type="submit" [disabled]="disabled" (click)="submit()">
      <img *ngIf="disabled" class="spinner-button" src="/assets/img/spinner.gif" alt="spinner" />
      {{ submitTitle }}
    </button>
  </mat-dialog-actions>
</ng-container>
