<h1 mat-dialog-title>
  {{ data ? 'Izmeni stanicu' : 'Dodaj stanicu ' }}
</h1>
<form [formGroup]="stationForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="label-mat">Naziv tipa stanice:</mat-label>
          <mat-select formControlName="stationType" required>
            <mat-option *ngFor="let station of stationsEnum" [value]="station.value">
              {{ station.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            <strong>{{ 'required' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="label-mat">Eksterni id:</mat-label>
          <input matInput formControlName="externalId" name="externalId" type="text" required />
          <mat-error>
            <strong>{{ 'required' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="label-mat">Ime:</mat-label>
          <input matInput formControlName="name" name="name" type="text" required />
          <mat-error>
            <strong>{{ 'required' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="label-mat">Ime (nominativ):</mat-label>
          <input matInput formControlName="name1" name="name1" type="text" required />
          <mat-error>
            <strong>{{ 'required' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="label-mat">Ime (genitiv):</mat-label>
          <input matInput formControlName="name2" name="name2" type="text" required />
          <mat-error>
            <strong>{{ 'required' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-6">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label class="label-mat">Ime (akuzativ): </mat-label>
          <input matInput formControlName="name4" name="name4" type="text" required />
          <mat-error>
            <strong>{{ 'required' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="mb-3">
      <mat-checkbox color="primary" formControlName="licensed" id="check-box">Licenca</mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button type="button" mat-dialog-close="true">Otkaži</button>
    <button
      #submitBtn
      mat-flat-button
      color="primary"
      type="submit"
      [disabled]="!(stationForm.valid && stationForm.dirty)"
    >
      Snimi
    </button>
  </mat-dialog-actions>
</form>
