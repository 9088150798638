<app-action-dialog
  [title]="name"
  [submitTitle]="'Promeni kolosek'"
  [disabled]="disabled"
  (submitted)="onSubmit()"
  [planInstanceId]="data.id"
>
  <div class="input-div">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label class="label-mat">Naziv stanice:</mat-label>
      <mat-select #station [errorStateMatcher]="matcher" [(value)]="selectedStation">
        <mat-option *ngFor="let station of stations$ | async" [value]="station">{{ station.station.name }} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="input-div">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label class="label-mat">Kolosek:</mat-label>
      <mat-select #track [errorStateMatcher]="matcher" [(value)]="selectedTrack">
        <mat-option *ngFor="let track of tracks$ | async" [value]="track">{{ track }} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</app-action-dialog>
