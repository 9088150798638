import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, startWith, Subject, Subscription, switchMap } from 'rxjs';
import { ActionsService } from '../../../../../services/actions.service';
import { ActionComponentData, PlanInstanceMessage } from '../../../../../models/realization.model';
import { MessageType } from '../../../../../utils/enums';
import { MESSAGE } from '../../../../../utils/messages';
import { SharedService } from '../../../../../services/shared.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  messages$: Observable<PlanInstanceMessage[]> = this.actionService.getPlanInstanceMessages(this.data.id);
  messages: PlanInstanceMessage[];
  message = this.fb.control(null, Validators.required);
  messageType = MessageType;
  name = 'Poruke za';
  displayedColumns = ['PORUKA', 'AKCIJE'];
  subscription: Subscription = new Subscription();
  private _gridReload = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ActionComponentData,
    private actionService: ActionsService,
    private fb: FormBuilder,
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.gridReload$
        .pipe(
          startWith(true),
          switchMap(() => this.messages$),
        )
        .subscribe(messages => (this.messages = messages)),
    );
  }

  removeMessage(messageId: number) {
    this.subscription.add(
      this.actionService.deletePlanInstanceMessage(this.data.id, messageId).subscribe({
        next: () => {
          this.reloadGrid();
          this.sharedService.showSuccessMessage(MESSAGE.success_delete);
        },
        error: error => {
          error?.errorMessages?.forEach((err: any) => {
            err
              ? this.sharedService.showMessage(err)
              : this.sharedService.showMessage(MESSAGE.error_notification_delete);
          });
        },
      }),
    );
  }

  createMessage() {
    if (this.message.valid) {
      this.subscription.add(
        this.actionService.createPlanInstanceMessage(this.data.id, this.message.value).subscribe({
          next: () => {
            this.message.reset(null);
            this.reloadGrid();
            this.sharedService.showSuccessMessage(MESSAGE.success_post);
          },
          error: error => {
            error?.errorMessages?.forEach((err: any) => {
              err
                ? this.sharedService.showMessage(err)
                : this.sharedService.showMessage(MESSAGE.error_notification_save);
            });
          },
        }),
      );
    }
  }

  get gridReload$(): Observable<void> {
    return this._gridReload.asObservable();
  }

  reloadGrid() {
    this._gridReload.next();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
