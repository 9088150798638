import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { Observable, pairwise } from 'rxjs';

export interface DisplayStateData {
  plan: number;
  delay: number;
  track?: number;
  shortNotification?: string;
}
export interface BlinkData {
  plan: boolean;
  delay: boolean;
  track?: boolean;
  shortNotification?: boolean;
}
export interface DisplayState {
  realizationItems: Map<number, DisplayStateData>;
}
export interface Blink {
  realizationItems: Map<number, BlinkData>;
}

@Injectable()
export class DisplayStore extends ComponentStore<DisplayState> {
  constructor() {
    super({
      realizationItems: new Map<number, DisplayStateData>(),
    });
  }

  // *********** Updaters *********** //
  readonly setRealizationItems = this.updater((state, value: Map<number, DisplayStateData>) => ({
    ...state,
    realizationItems: value,
  }));

  // *********** Selectors *********** //
  readonly realizationItems$: Observable<Map<number, DisplayStateData>> = this.select(state => state.realizationItems);

  private readonly change$ = this.state$.pipe(pairwise());

  readonly blink$: Observable<Blink> = this.select(
    this.change$,
    ([previous, current]) => {
      let plan: boolean, delay: boolean, track: boolean, shortNotification: boolean;
      const realizationItems = new Map<number, BlinkData>();
      const currentRealizationItems = current.realizationItems;
      const previousRealizationItems = previous.realizationItems;

      for (const [key, value] of currentRealizationItems.entries()) {
        if (previousRealizationItems.has(key)) {
          plan = previousRealizationItems.get(key)?.plan !== value.plan;
          delay = previousRealizationItems.get(key)?.delay !== value.delay;
          track = previousRealizationItems.get(key)?.track !== value.track;
          shortNotification = previousRealizationItems.get(key)?.shortNotification !== value.shortNotification;
        } else {
          plan = true;
          delay = false;
          track = false;
          shortNotification = false;
        }
        realizationItems.set(key, { plan, delay, track, shortNotification });
      }
      return { realizationItems };
    },
    { debounce: true },
  );
}
