<div class="d-flex gap-2">
  <div>
    <mat-icon [svgIcon]="data.icon" class="note"></mat-icon>
  </div>
  <div class="pt-1">{{ data.message }}</div>
  <div *ngIf="hasAction" style="margin-top: -5px">
    <button mat-icon-button (click)="action()">
      <mat-icon svgIcon="clear" class="close" [matTooltip]="data.action!"></mat-icon>
    </button>
  </div>
</div>