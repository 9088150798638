import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '../../../../../services/shared.service';
import { UserService } from '../../../../../services/user.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MESSAGE } from '../../../../../utils/messages';
import { Subscription } from 'rxjs';
import { Role, User } from '../../../../../models/user.model';
import { GridModal } from '../../../../../models/grid-modal';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss'],
})
export class UserRoleComponent implements OnInit, OnDestroy {
  currentlyCheckedRoles: Role[] = [];
  subscription: Subscription = new Subscription();
  roles = Role;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GridModal<User>,
    public dialogRef: MatDialogRef<UserRoleComponent>,
    public sharedService: SharedService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.currentlyCheckedRoles = this.data.content.roles || [];
  }

  closeModal() {
    this.dialogRef.close();
  }

  onChecked(event: MatCheckboxChange, role: Role) {
    if (event.checked) {
      this.currentlyCheckedRoles.push(role);
    } else {
      for (let i = 0; i < this.currentlyCheckedRoles.length; i++) {
        if (this.currentlyCheckedRoles[i] === role) {
          if (this.currentlyCheckedRoles.length > 1) this.currentlyCheckedRoles.splice(i, 1);
          else if (this.currentlyCheckedRoles.length === 1) this.currentlyCheckedRoles = [];
        }
      }
    }
  }

  onSubmit() {
    this.subscription.add(
      this.userService.postRoles(this.currentlyCheckedRoles, this.data.id).subscribe({
        next: () => {
          this.sharedService.openSnackBar(MESSAGE.success_put);
          this.currentlyCheckedRoles = [];
          this.sharedService.reloadGrid();
          this.closeModal();
        },
        error: errorMessage => {
          errorMessage.appCode
            ? this.sharedService.showMessage(errorMessage.appCode)
            : this.sharedService.showMessage(MESSAGE.error_user_role_save);
        },
      }),
    );
  }

  isRole(role: Role) {
    let isSentRole = false;
    this.data.content.roles?.forEach((rol: any) => {
      if (rol === role) isSentRole = true;
    });
    return isSentRole;
  }

  getUserRole(role: Role): string {
    return this.userService.getUserRole(role);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
