<div class="main-div">
  <h1 class="main-header-text">Operatori</h1>
  <div class="flex table-top-area">
    <div class="add-station-div">
      <button mat-icon-button disableRipple (click)="openDialog()">
        <mat-icon svgIcon="add" class="add" matTooltip="Kreiraj operatora"></mat-icon>
      </button>
    </div>
  </div>
  <!---Table--->
  <table class="table-style" mat-table [dataSource]="data">
    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="NAME">
      <th mat-header-cell *matHeaderCellDef>Naziv</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="SMALL_LOGO">
      <th mat-header-cell *matHeaderCellDef>Mini logo</th>
      <td mat-cell *matCellDef="let element">
        <img [src]="'data:image/png;base64, ' + element?.smallLogo | safe : 'url'" width="45px" alt="" />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
