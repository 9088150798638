import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Version } from '../../../../models/shared.model';
import { SharedService } from '../../../../services/shared.service';
import { MenuService } from '../../../../services/menu.service';
import { IconLoaderService } from '../../../../services/icon-loader.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [MenuService, IconLoaderService],
})
export class HomeComponent {
  toggleSideBar$: Observable<boolean> = this.menuService.menuToggle$.pipe(map(sidebar => !sidebar));
  version$: Observable<Version> = this.sharedService.getCopyrightMarker();
  constructor(
    private iconLoader: IconLoaderService,
    private sharedService: SharedService,
    private menuService: MenuService,
  ) {}
}
