<h1 mat-dialog-title>Promena lozinke</h1>
<div [formGroup]="changePasswordForm">
  <mat-dialog-content>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label class="label-mat">Stara lozinka:</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" formControlName="oldPassword" [errorStateMatcher]="matcher" />
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide Password'"
        [attr.aria-pressed]="hide"
      >
        <img class="vis" [src]="hide ? '../../../assets/icons/vis.svg' : '../../../assets/icons/vis_off.svg'" alt="" />
      </button>
    </mat-form-field>
    <mat-error
      *ngIf="sharedService.validationMessage('oldPassword', changePasswordForm, isFormSubmitted)"
      name="oldPassword"
    >
      <strong>{{ 'required' | translate }}</strong>
    </mat-error>

    <mat-form-field class="w-100" appearance="outline">
      <mat-label class="label-mat">Nova lozinka:</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" formControlName="newPassword" [errorStateMatcher]="matcher" />
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide Password'"
        [attr.aria-pressed]="hide"
      >
        <img class="vis" [src]="hide ? '../../../assets/icons/vis.svg' : '../../../assets/icons/vis_off.svg'" alt="" />
      </button>
    </mat-form-field>
    <mat-error
      *ngIf="sharedService.validationMessage('newPassword', changePasswordForm, isFormSubmitted)"
      name="newPassword"
    >
      <strong>{{ 'required' | translate }}</strong>
    </mat-error>

    <mat-form-field class="w-100" appearance="outline">
      <mat-label class="label-mat">Nova lozinka (Unesite ponovo):</mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        formControlName="newPasswordRepeat"
        [errorStateMatcher]="matcher"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide Password'"
        [attr.aria-pressed]="hide"
      >
        <img class="vis" [src]="hide ? '../../../assets/icons/vis.svg' : '../../../assets/icons/vis_off.svg'" alt="" />
      </button>
    </mat-form-field>
    <mat-error
      *ngIf="
        this.changePasswordForm.get('newPasswordRepeat')?.value !== this.changePasswordForm.get('newPassword')?.value
      "
      name="name2"
    >
      <strong>{{ this.appMessage.error_password_change }}</strong>
    </mat-error>
    <mat-error
      *ngIf="sharedService.validationMessage('newPasswordRepeat', changePasswordForm, isFormSubmitted)"
      name="newPasswordRepeat"
    >
      <strong>{{ 'required' | translate }}</strong>
    </mat-error>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-flat-button type="button" mat-dialog-close>Otkaži</button>
    <button mat-flat-button color="primary" type="submit" mat-dialog-close (click)="changePasswordSubmit()">
      Potvrdi
    </button>
  </mat-dialog-actions>
</div>
