import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Station, StationData } from 'src/app/models/stations.model';
import { StationService } from 'src/app/services/station.service';
import { SharedService } from 'src/app/services/shared.service';
import { MESSAGE } from 'src/app/utils/messages';
import { GridModal } from '../../../../../models/grid-modal';

@Component({
  selector: 'app-station-modal',
  templateUrl: './station-modal.component.html',
  styleUrls: ['./station-modal.component.scss'],
})
export class StationModalComponent implements OnInit, OnDestroy {
  @ViewChild('submitBtn', { static: true }) submitBtn: MatButton;
  stationForm: FormGroup = this.fb.group({
    stationType: ['', Validators.required],
    name: ['', Validators.required],
    name1: ['', Validators.required],
    name2: ['', Validators.required],
    name4: ['', Validators.required],
    licensed: [false],
    externalId: ['', Validators.required],
  });
  subscription: Subscription = new Subscription();
  stationsEnum = [
    { value: 'STATION', name: 'Stanica' },
    { value: 'STOP', name: 'Stajalište' },
  ];
  newStation: StationData = new StationData('', '', '', '', '', false, '');

  constructor(
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: GridModal<Station>,
    private stationService: StationService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<StationModalComponent>,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.stationForm.patchValue(this.data.content);
    }
  }

  onSubmit(): void {
    if (this.stationForm.valid) {
      this.submitBtn.disabled = true;
      this.newStation = this.stationForm.value;

      this.subscription.add(
        this.stationService.upsert(this.newStation, this.data?.id).subscribe({
          next: () => {
            this.sharedService.showSuccessMessage(this.data ? MESSAGE.success_put : MESSAGE.success_post);
            this.sharedService.reloadGrid();
            this.closeModal();
          },
          error: error => {
            this.submitBtn.disabled = false;
            error.errorMessages?.length
              ? this.sharedService.showMessage(error.errorMessages[0])
              : this.sharedService.showMessage(this.data ? MESSAGE.error_station_edit : MESSAGE.error_station_save);
          },
        }),
      );
    } else {
      this.sharedService.showMessage(MESSAGE.error_station_input);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
