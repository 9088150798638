<div class="main-station-dialog">
  <form [formGroup]="userStationForm" (ngSubmit)="onClickAddStation()">
    <h1 mat-dialog-title>{{ 'Stanice korisnika ' }} {{ data.content.username }}</h1>

    <div class="table-div">
      <div>
        <mat-form-field appearance="outline">
          <mat-label class="label-mat">Izaberi stanicu:</mat-label>
          <mat-select formControlName="selectedStation">
            <mat-option *ngFor="let station of allStations$ | async" [value]="station">
              {{ station.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error
          *ngIf="sharedService.validationMessage('selectedStation', userStationForm, isFormSubmitted)"
          name="selectedStation"
        >
          <strong>{{ 'required' | translate }}</strong>
        </mat-error>
      </div>

      <div class="form">
        <div class="form-div">
          <div class="add-new-panel">
            <div class="add-sub-btn">
              <button mat-raised-button color="primary" type="submit" (mousedown)="$event.preventDefault()">
                <img src="/assets/icons/add.svg" class="icons" alt="" />
                Dodaj stanicu
              </button>
            </div>
          </div>
          <mat-dialog-content class="table-div">
            <table mat-table [dataSource]="userStations" class="my-3 table-style">
              <div>
                <ng-container matColumnDef="NAZIV STANICE">
                  <th mat-header-cell *matHeaderCellDef><strong>NAZIV STANICE</strong></th>
                  <td mat-cell *matCellDef="let station; index as i">
                    <p>{{ station.station.name }}</p>
                  </td>
                </ng-container>
                <ng-container matColumnDef="AKCIJE">
                  <th mat-header-cell *matHeaderCellDef><strong>AKCIJE</strong></th>
                  <td mat-cell *matCellDef="let station; index as i">
                    <button mat-icon-button (click)="removeUserStation(station.id)">
                      <mat-icon svgIcon="delete" class="delete" matTooltip="Obriši"></mat-icon>
                    </button>
                  </td>
                </ng-container>
              </div>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </mat-dialog-content>
        </div>
      </div>
      <div mat-dialog-actions class="btn-div">
        <div></div>
        <div>
          <button class="btn-closed" mat-flat-button type="button" (click)="closeModal()">Zatvori</button>
        </div>
      </div>
    </div>
  </form>
</div>
