import { AfterViewInit, Component, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { map, merge, Observable, Subscription, switchMap, tap } from 'rxjs';
import { Station } from '../../../../models/stations.model';
import { InfoTable, InfoTableStatus, InfoTableType } from '../../../../models/info-table';
import { SearchComponent } from '../reusable-component/search/search.component';
import { InfoTableService } from '../../../../services/info-table.service';
import { SharedService } from '../../../../services/shared.service';
import { MESSAGE } from '../../../../utils/messages';
import { SELECTED_STATION_KEY } from '../../../../utils/constants';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-info-table-monitor',
  templateUrl: './info-table-monitor.component.html',
  styleUrls: ['./info-table-monitor.component.scss'],
  providers: [InfoTableService],
})
export class InfoTableMonitorComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSelect) select: MatSelect;
  @ViewChild(SearchComponent) search: SearchComponent;
  readonly infoTableStatus = InfoTableStatus;
  readonly infoTableType = InfoTableType;
  data: InfoTable[];
  pageSize: number = 10;
  total: number;
  displayedColumns: string[] = ['ID', 'TYPE', 'NAME', 'IP', 'TRACK', 'ACK', 'PING', 'STATUS'];
  private initialData: EventEmitter<void> = new EventEmitter<void>();
  stations$: Observable<Station[]> = this.userService.getAuthUserStations().pipe(
    tap(stations => {
      const selected = window.localStorage.getItem(SELECTED_STATION_KEY);
      if (stations.length === 1) {
        this.selectedStation = stations[0];
        this.storeSelectedStation();
        this.initialData.emit();
      } else if (selected) {
        const selectedStation = JSON.parse(selected) as Station;
        if (stations.some(station => station.id === selectedStation.id)) {
          this.selectedStation = selectedStation;
          this.initialData.emit();
        }
      }
    }),
  );
  selectedStation: Station;
  subscription: Subscription = new Subscription();
  constructor(
    private service: InfoTableService,
    private userService: UserService,
    private sharedService: SharedService,
  ) {}

  ngAfterViewInit(): void {
    this.subscription.add(
      merge(
        this.paginator.page,
        this.sort.sortChange,
        this.select.selectionChange,
        this.initialData,
        this.sharedService.gridReload$,
      )
        .pipe(
          switchMap(events => {
            if (
              events instanceof MatSelectChange ||
              (typeof events === 'object' && events.hasOwnProperty('searchText'))
            ) {
              this.paginator.pageIndex = 0;
            }
            return this.service.monitor(
              this.selectedStation.id,
              this.paginator.pageIndex,
              this.paginator.pageSize,
              this.sort.active,
              this.sort.direction,
            );
          }),
          map(data => {
            if (data === null) {
              return [];
            }
            this.total = data.totalElements;
            return data.content;
          }),
        )
        .subscribe({
          next: data => (this.data = data),
          error: (error: any) => {
            error.message
              ? this.sharedService.showMessage(error.message)
              : this.sharedService.showMessage(MESSAGE.error_get_realization);
          },
        }),
    );
  }

  storeSelectedStation(): void {
    window.localStorage.setItem(SELECTED_STATION_KEY, JSON.stringify(this.selectedStation));
  }

  compareStations(s1: any, s2: any): boolean {
    return s1 && s2 && s1.id === s2.id;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
