<app-action-dialog
  [title]="name"
  [planInstanceId]="data.id"
>
  <div class="input-div">
    <mat-form-field class="w-100 name-field" appearance="outline">
      <mat-label class="label-mat">Poruka</mat-label>
      <input matInput [formControl]="message" type="text" />
      <button
        matSuffix
        mat-icon-button
        matTooltip="Kreiraj poruku"
        aria-label="Kreiraj poruku"
        (click)="createMessage()"
        [disabled]="!message.valid"
      >
        <mat-icon>save</mat-icon>
      </button>
      <mat-error>
        <strong>{{ 'required' | translate }}</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <ng-container *ngIf="messages?.length; else nomsg">
  <section class="example-container mat-elevation-z2 mb-3" tabindex="0">
    <table mat-table [dataSource]="messages">
      <div>
        <ng-container matColumnDef="PORUKA">
          <th mat-header-cell *matHeaderCellDef>PORUKA</th>
          <td mat-cell *matCellDef="let element">
            {{ element.message }}
          </td>
        </ng-container>
        <ng-container matColumnDef="AKCIJE">
          <th mat-header-cell *matHeaderCellDef>AKCIJA</th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="element.messageType === messageType.USER_MESSAGE" mat-icon-button type="button">
              <mat-icon matTooltip="Obriši" [matTooltipPosition]="'above'" (click)="removeMessage(element.id)">
                delete
              </mat-icon>
            </button>
          </td>
        </ng-container>
      </div>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </section>
  </ng-container>
  <ng-template #nomsg>
    <p>Nema kreiranih poruka.</p>
  </ng-template>
</app-action-dialog>
