<section class="table-container" tabindex="0">
  <ng-container *ngIf="data?.length; else emptyHistory">
    <table class="mb-3 table-style" mat-table [dataSource]="data">
      <div>
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef>Datum akcije</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdDate | date : 'HH:mm:ss' }} <br />{{ element.createdDate | date : 'dd.MM.yyyy' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action" class="id-width">
          <th mat-header-cell *matHeaderCellDef>Akcija</th>
          <td mat-cell *matCellDef="let element">{{ getActionTranslation(element.action) }}</td>
        </ng-container>

        <ng-container matColumnDef="station" class="id-width">
          <th mat-header-cell *matHeaderCellDef>Naziv stanice</th>
          <td mat-cell *matCellDef="let element">{{ element?.station?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="delay">
          <th mat-header-cell *matHeaderCellDef>kašnjenje</th>
          <td mat-cell *matCellDef="let element">{{ element.delay }}</td>
        </ng-container>

        <ng-container matColumnDef="track">
          <th mat-header-cell *matHeaderCellDef>Kolosek</th>
          <td mat-cell *matCellDef="let element">{{ element.track }}</td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>Operater</th>
          <td mat-cell *matCellDef="let element">{{ element?.createdBy }}</td>
        </ng-container>
      </div>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsActionHistory; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsActionHistory"></tr>
    </table>
  </ng-container>
</section>
<ng-template #emptyHistory><p class="m-3">Ne postoji istorija akcija za ovu realizaciju.</p></ng-template>
