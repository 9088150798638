<div class="main-div">
  <div>
    <div class="station-menu">
      <div class="d-flex align-items-center">
        <span class="station-name">{{ selectedStation?.name || 'Izaberite stanicu' }}</span>
        <button mat-icon-button disableRipple aria-label="Stanica" (click)="stationList = !stationList">
          <mat-icon svgIcon="arrow-sort" class="arrow-sort"></mat-icon>
        </button>
      </div>
      <div class="station-list" [class.opened]="stationList">
        <p>Stanica</p>
        <mat-radio-group
          aria-labelledby="station-radio-group-label"
          class="station-radio-group"
          [value]="selectedStation"
          (change)="stationList = false; storeSelectedStation($event.value)"
          name="station"
        >
          <mat-radio-button
            color="primary"
            class="station-radio-button"
            *ngFor="let station of stations$ | async"
            [value]="station"
          >
            {{ station.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center table-top-area">
    <div>
      <app-search></app-search>
    </div>
    <div class="d-flex align-items-center tbl-actions">
      <div class="d-flex justify-content-end align-items-center">
        <input matInput disabled [max]="maxDate" [matDatepicker]="picker" class="archive-date border-0 bg-transparent" [(ngModel)]="serializedDate" (dateChange)="onDateChange($event)">
        <mat-datepicker-toggle aria-label="Arhiva" matTooltip="Arhiva" [for]="picker">
          <mat-icon matDatepickerToggleIcon svgIcon="calendar" class="calendar"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
      </div>
      <mat-button-toggle #toggle disableRipple [disabled]="!selectedStation?.id" aria-label="Prikaži sve statuse">
        <mat-icon class="show" [class.active]="toggle.checked" svgIcon="show" matTooltip="Prikaži sve statuse"></mat-icon>
      </mat-button-toggle>
      <ng-container *ngIf="archive; else noarchive">
        <button mat-icon-button disableRipple aria-label="Zatvori" (click)="closeArchive()">
          <mat-icon class="close" svgIcon="close" matTooltip="Zatvori"></mat-icon>
        </button>
      </ng-container>
      <ng-template #noarchive>
        <button mat-icon-button disableRipple aria-label="Osveži" (click)="loadData()">
          <mat-icon class="refresh" svgIcon="refresh" matTooltip="Osveži"></mat-icon>
        </button>
      </ng-template>
    </div>
  </div>
  <table class="table-style" mat-table [dataSource]="data">
    <ng-container matColumnDef="ID" class="id-width">
      <th mat-header-cell *matHeaderCellDef>ID Instance</th>
      <td mat-cell *matCellDef="let element">
        {{ element.planInstanceItem.planInstanceId }}<br />{{ element.planInstanceItem.id }}
      </td>
    </ng-container>

    <!--      <ng-container matColumnDef="OPERATOR">-->
    <!--        <th mat-header-cell *matHeaderCellDef>OPERATOR</th>-->
    <!--        <td mat-cell *matCellDef="let element">-->
    <!--          <img [src]="url + '/operators/' + element.train.operator.id + '/small-logo'" class="logo" alt="logo" />-->
    <!--        </td>-->
    <!--      </ng-container>-->

    <ng-container matColumnDef="CATEGORY">
      <th mat-header-cell *matHeaderCellDef>Kategorija</th>
      <td mat-cell *matCellDef="let element" class="train-category">{{ element.train.trainCategory.code }}</td>
    </ng-container>

    <ng-container matColumnDef="TRAIN">
      <th mat-header-cell *matHeaderCellDef>Voz</th>
      <td mat-cell *matCellDef="let element">{{ element.train.id }}</td>
    </ng-container>

    <ng-container matColumnDef="TRANZIT">
      <th mat-header-cell *matHeaderCellDef>Tranzit</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
          class="transit"
          svgIcon="yes"
          *ngIf="element.planInstanceItem.transit"
          matTooltip="Tranzit"
        ></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="ROUTE">
      <th mat-header-cell *matHeaderCellDef>Ruta</th>
      <td mat-cell *matCellDef="let element">
        {{ element.routeHeader.firstStation.name }}<br />{{ element.routeHeader.lastStation.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="KOLOSEK">
      <th mat-header-cell *matHeaderCellDef>Kolosek</th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngIf="
            !element.realizationItem.track || element.planInstanceItem.track === element.realizationItem.track;
            else differentTrack
          "
        >
          {{ element.planInstanceItem.track }}</ng-container
        >
        <ng-template #differentTrack>{{
          element.planInstanceItem.track + ' -> ' + element.realizationItem.track
        }}</ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="TIME_ARRIVAL">
      <th mat-header-cell *matHeaderCellDef>Planiran dolazak</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.planInstanceItem.planTimeArrival; else noinfo">
          <span class="fw-bolder">{{ element.planInstanceItem.planTimeArrival | date : 'HH:mm' }}</span>
          <br />
          {{ element.planInstanceItem.planTimeArrival | date : 'dd.MM.yyyy' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="TIME_DEPARTURE">
      <th mat-header-cell *matHeaderCellDef>Planiran polazak</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.planInstanceItem.planTimeDeparture; else noinfo">
          <span class="fw-bolder">{{ element.planInstanceItem.planTimeDeparture | date : 'HH:mm' }}</span>
          <br />
          {{ element.planInstanceItem.planTimeDeparture | date : 'dd.MM.yyyy' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="ARRIVAL_DELAY">
      <th mat-header-cell *matHeaderCellDef>Kašnjenje<br />u dolasku</th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngIf="element?.realizationItem?.arrivalDelay || element?.realizationItem?.arrivalDelay === 0; else noinfo"
        >
          <span [class.light]="!element?.realizationItem?.arrivalDelay">{{
            element?.realizationItem?.arrivalDelay
          }}</span>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="DEPARTURE_DELAY">
      <th mat-header-cell *matHeaderCellDef>Kašnjenje<br />u polasku</th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngIf="
            element?.realizationItem?.departureDelay || element?.realizationItem?.departureDelay === 0;
            else noinfo
          "
        >
          <span [class.light]="!element?.realizationItem?.departureDelay">{{
            element?.realizationItem?.departureDelay
          }}</span>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="SKR">
      <th mat-header-cell *matHeaderCellDef>Skraćenje</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="short" svgIcon="yes" *ngIf="element.shortened" matTooltip="Skraćivanje"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="STATUS">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <span [class.light]="element.description.id === 1">{{ element.description.description }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="ACTION_STATUS">
      <th mat-header-cell *matHeaderCellDef>Akcije</th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex align-items-center" style="width: 147px; height: 28px">
          <div style="width: 50px">
            <ng-container [ngSwitch]="element.actionStatus">
              <mat-icon
                class="warning"
                svgIcon="warning"
                matTooltip="Potrebna akcija"
                *ngSwitchCase="actionStatus.ACTION_NEEDED"
              ></mat-icon>
              <mat-icon
                class="yes-action"
                svgIcon="yes-action"
                matTooltip="Akcija izvršena"
                *ngSwitchCase="actionStatus.ACTION_DONE"
              ></mat-icon>
            </ng-container>
          </div>
          <div class="cell-actions">
            <button mat-icon-button color="primary" (click)="openDetail(element.planInstanceItem.planInstanceId)">
              <mat-icon svgIcon="details" class="details"></mat-icon>
            </button>
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Akcije za instancu plana"
              (menuOpened)="onActionMenuOpen(element.planInstanceItem.planInstanceId)"
            >
              <mat-icon svgIcon="arrow-sort" class="arrow-sort"></mat-icon>
            </button>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="paginator">
    <mat-paginator [length]="total" [pageSize]="pageSize" [hidePageSize]="true" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>
<mat-menu #menu="matMenu" xPosition="before">
  <ng-template actionsHost></ng-template>
</mat-menu>
<ng-template #noinfo>
  <span class="light">—</span>
</ng-template>
