<nav>
  <div class="navbar-brand">
    <a class="" (click)="toggleSideBar()">
      <img src="/assets/icons/menu.svg" class="menu-icon" alt="" />
      <span class="text-white">Audio Vizuelni Informacioni Sistem</span>
    </a>
  </div>
  <div class="navbar-actions">
      <a class="help" href="/tutorials/user-tutorial-operater.pdf" target="_blank"><mat-icon>help</mat-icon></a>
      <img
        src="/assets/icons/jobs.svg"
        routerLink="jobs"
        matTooltip="Statusi poslova"
        matTooltipPosition="below"
        class="jobs-icon"
        alt=""
      />
    <div class="user-menu" [matMenuTriggerFor]="userMenu">
      <a>{{ user?.fullName?.charAt(0)?.toLocaleUpperCase() }}</a>
    </div>
  </div>
  <mat-menu #userMenu="matMenu" class="user-menu-dropdown">
    <button mat-menu-item disabled>{{ user?.fullName }}</button>
    <button mat-menu-item (click)="changePasswordDialog()">Promena loznike</button>
    <button mat-menu-item (click)="openDialog()">Odjavi se</button>
  </mat-menu>
</nav>
