import { AfterViewInit, Component, Inject, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { map, Observable, tap } from 'rxjs';
import { MyErrorStateMatcher } from 'src/app/directives/error-directive.directive';
import { ActionsService } from 'src/app/services/actions.service';
import { RealizationService } from 'src/app/services/realization.service';
import { SharedService } from 'src/app/services/shared.service';
import { MESSAGE } from 'src/app/utils/messages';
import { ActionComponentData, StationActionData } from '../../../../../models/realization.model';
import { Action } from '../../../../../utils/enums';

@Component({
  selector: 'app-change-track',
  templateUrl: './change-track.component.html',
  styleUrls: ['./change-track.component.scss'],
})
export class ChangeTrackComponent implements AfterViewInit {
  @ViewChild('station', { static: true }) station: MatSelect;
  @ViewChild('track', { static: true }) track: MatSelect;
  name: string = 'Promena koloseka';
  matcher = new MyErrorStateMatcher();
  selectedStation: StationActionData;
  selectedTrack: number;
  tracks$: Observable<number[]>;
  stations$: Observable<StationActionData[]> = this.realizationService
    .stationsForAction(this.data.selectedStation.id, this.data.id, Action.CHANGE_TRACK)
    .pipe(
      tap(stations => {
        const defaultStation = stations.find(station => station.isDefault);
        this.selectedStation = defaultStation ?? stations[0];
        this.station.selectionChange.emit();
      }),
    );
  private _disabled = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ActionComponentData,
    public dialogRef: MatDialogRef<ChangeTrackComponent>,
    private actionService: ActionsService,
    private sharedService: SharedService,
    private realizationService: RealizationService,
  ) {}

  ngAfterViewInit(): void {
    this.tracks$ = this.station.selectionChange.asObservable().pipe(
      tap(() => (this.selectedTrack = this.selectedStation?.defaultTrack)),
      map(() => this.selectedStation?.tracks),
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  validate(): boolean {
    return !!this.selectedStation?.station?.id && !!this.selectedTrack;
  }

  onSubmit() {
    if (this.validate()) {
      this.disabled = true;
      this.actionService.changeTrack(this.data.id, this.selectedStation.station.id, this.selectedTrack).subscribe({
        next: () => {
          this.sharedService.openSnackBar(MESSAGE.success_post);
          this.sharedService.reloadGrid();
          this.closeDialog();
        },
        error: error => {
          this.disabled = false;
          error.errorMessages && error.errorMessages.length
            ? this.sharedService.showMessage(error.errorMessages[0])
            : this.sharedService.showMessage(MESSAGE.RUNTIME_ERROR);
        },
      });
    } else {
      this.sharedService.openSnackBarError('Morate izabrati stanicu i kolosek!');
    }
  }
}
