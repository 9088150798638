import { Station } from './stations.model';
import { Base } from './base.model';

export enum InfoTableType {
  TRACK = 'TRACK',
  ARRIVAL = 'ARRIVAL',
  DEPARTURE = 'DEPARTURE',
  ARRIVAL_DEPARTURE = 'ARRIVAL_DEPARTURE',
}

export enum InfoTableStatus {
  OK = 'OK',
  NOK = 'NOK',
}

export interface InfoTableMessage {
  infoTableId: number;
  stationId: number;
  type: InfoTableType;
}

export interface InfoTableTrackMessage extends InfoTableMessage {
  track: number;
}

export interface InfoTable extends Base {
  id: number;
  station: Station;
  infoTableType: InfoTableType;
  name: string;
  ipAddress: string;
  track: number;
  acknowledgeDate: Date;
  pingDate: Date;
  infoTableStatus: InfoTableStatus;
}
