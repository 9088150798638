import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { filter, Observable, Subscription, switchMap, tap } from 'rxjs';
import * as moment from 'moment';
import { Premise, Station, Track } from 'src/app/models/stations.model';
import { StationService } from 'src/app/services/station.service';
import { UserService } from 'src/app/services/user.service';
import { MESSAGE } from 'src/app/utils/messages';
import { SELECTED_STATION_KEY } from '../../../../../utils/constants';
import { AudioMessageService } from '../../../../../services/audio-message.service';
import { AudioMessageRequest, MessagePriority } from '../../../../../models/audio-message.model';
import { trackOrPremisesSelectedValidator } from '../../../../../utils/validators';
import { SharedService } from '../../../../../services/shared.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements AfterViewInit, OnDestroy {
  @ViewChild('stationsSelect') stationsSelect: MatSelect;
  @ViewChild('tracksSelect') tracksSelect: MatSelect;
  @ViewChild('premisesSelect') premisesSelect: MatSelect;
  @ViewChild(MatCheckbox) checkbox: MatCheckbox;
  @ViewChild(FormGroupDirective) msgForm: FormGroupDirective;

  checkboxChecked = true;
  priorities = MessagePriority;
  showSpinners = true;
  showSeconds = true;
  touchUi = false;
  enableMeridian = false;
  minDate: moment.Moment;
  maxDate: moment.Moment;
  stepHour = 1;
  stepMinute = 1;
  stepSecond = 1;
  palette: ThemePalette = 'primary';
  selectedStation: Station;
  stations$: Observable<Station[]> = this.userService.getAuthUserStations().pipe(
    filter(stations => !!stations.length),
    tap(stations => {
      this.station.enable();
      const selected = window.localStorage.getItem(SELECTED_STATION_KEY);
      if (stations.length === 1) {
        this.selectedStation = stations[0];
        this.stationsSelect.selectionChange.emit();
        this.storeSelectedStation();
      } else if (selected) {
        const selectedStation = JSON.parse(selected) as Station;
        if (stations.some(station => station.id === selectedStation.id)) {
          this.selectedStation = selectedStation;
          this.stationsSelect.selectionChange.emit();
        }
      }
    }),
  );
  tracks$: Observable<Track[]>;
  premises$: Observable<Premise[]>;
  subscription: Subscription = new Subscription();

  form: FormGroup = this.fb.group(
    {
      stationId: [{ value: null, disabled: true }, Validators.required],
      tracks: [{ value: [], disabled: true }],
      premiseIds: [{ value: [], disabled: true }],
      messageText: [null, Validators.required],
      messagePriority: [MessagePriority.HIGH, Validators.required],
      emittingDateFrom: [{ value: this.getCurrentTimeWithoutSeconds(), disabled: true }],
    },
    { validators: trackOrPremisesSelectedValidator },
  );

  constructor(
    private stationService: StationService,
    private fb: FormBuilder,
    private userService: UserService,
    private audioMessageService: AudioMessageService,
    private sharedService: SharedService,
  ) {}

  get dateTimeControl() {
    return this.form.get('emittingDateFrom') as FormControl;
  }

  get station() {
    return this.form.get('stationId') as FormControl;
  }

  get tracks() {
    return this.form.get('tracks') as FormControl;
  }

  get premises() {
    return this.form.get('premiseIds') as FormControl;
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.checkbox.change.asObservable().subscribe(change => {
        this.checkboxChecked = change.checked;
        change.checked ? this.dateTimeControl.disable() : this.dateTimeControl.enable();
        this.dateTimeControl.patchValue(this.getCurrentTimeWithoutSeconds());
      }),
    );

    this.tracks$ = this.stationsSelect.selectionChange.asObservable().pipe(
      switchMap(() =>
        this.stationService.getTrackById(this.selectedStation.id).pipe(
          tap(tracks => {
            this.tracks.reset([]);
            tracks.length ? this.tracks.enable() : this.tracks.disable();
          }),
        ),
      ),
    );

    this.premises$ = this.stationsSelect.selectionChange.asObservable().pipe(
      switchMap(() =>
        this.stationService.getStationPremises(this.selectedStation.id).pipe(
          tap(premises => {
            this.premises.reset([]);
            premises.length ? this.premises.enable() : this.premises.disable();
          }),
        ),
      ),
    );
  }

  protected getCurrentTimeWithoutSeconds(): Date {
    const now = new Date();
    now.setSeconds(0);
    now.setMilliseconds(0);
    now.setMinutes(now.getMinutes() + 1);
    return now;
  }

  protected prepareData() {
    const data: AudioMessageRequest = {
      ...this.form.value,
      stationId: this.selectedStation.id,
      emittingDateFrom: this.checkboxChecked ? null : this.toISOStringLocale(this.dateTimeControl.value),
    };
    return data;
  }

  protected toISOStringLocale(date: Date) {
    const offsetMs = date.getTimezoneOffset() * 60 * 1000;
    const msLocal = date.getTime() - offsetMs;
    const dateLocal = new Date(msLocal);
    return dateLocal.toISOString();
  }

  submit() {
    if (this.form.valid) {
      const formData = this.prepareData();
      this.subscription.add(
        this.audioMessageService.post(formData).subscribe({
          next: () => {
            this.sharedService.showSuccessMessage(MESSAGE.success_message);
            this.sharedService.reloadGrid();
            this.resetForm();
          },
          error: (error: any) => {
            error.errorMessages && error.errorMessages.length
              ? this.sharedService.showMessage(error.appCode)
              : this.sharedService.showMessage(MESSAGE.RUNTIME_ERROR);
          },
        }),
      );
    }
  }

  protected resetForm() {
    this.checkboxChecked = true;
    this.msgForm.resetForm({
      stationId: this.selectedStation,
      tracks: [],
      premiseIds: [],
      messageText: null,
      messagePriority: MessagePriority.HIGH,
      emittingDateFrom: { value: this.getCurrentTimeWithoutSeconds(), disabled: true },
    });
  }

  storeSelectedStation(): void {
    window.localStorage.setItem(SELECTED_STATION_KEY, JSON.stringify(this.selectedStation));
  }

  compareStations(s1: any, s2: any): boolean {
    return s1 && s2 && s1.id === s2.id;
  }

  getPriorityLabel(priority: MessagePriority): string {
    switch (priority) {
      case MessagePriority.NORMAL:
        return 'Uobičajen';
      case MessagePriority.LOW:
        return 'Nizak';
      case MessagePriority.HIGH:
        return 'Visok';
      default:
        return priority;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
