import { Component, Input } from '@angular/core';
import { Station } from '../../../../../../models/stations.model';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
  @Input('station') station: Station;
}
