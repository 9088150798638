import { Component, EventEmitter, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteModalComponent } from '../../../../../../modals/delete-modal/delete-modal.component';

export type DeleteCacheType = 'item' | 'clear';

export interface DeleteCacheEvent {
  type: DeleteCacheType;
}

@Component({
  selector: 'app-delete-cache',
  templateUrl: './delete-cache.component.html',
  styleUrls: ['./delete-cache.component.scss'],
})
export class DeleteCacheComponent {
  deleteEvent: EventEmitter<DeleteCacheEvent> = new EventEmitter<DeleteCacheEvent>();
  constructor(
    public dialogRef: MatDialogRef<DeleteModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { name: string },
  ) {}

  delete() {
    this.deleteEvent.emit({ type: this.data ? 'item' : 'clear' });
  }
}
