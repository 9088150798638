<h2 mat-dialog-title>Obriši</h2>
<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="data; else clear">
    <p style="padding-bottom: 5px">Da li ste sigurni da želite da obrišete stavku keša?</p>
    <p><strong>Naziv:</strong> {{ data.name }}</p>
  </ng-container>
  <ng-template #clear>
    <p style="padding-bottom: 10px">Da li ste sigurni da želite da obrišete celokupan keš?</p>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button type="button" mat-dialog-close="false">Otkaži</button>
  <button mat-flat-button color="primary" type="submit" (click)="delete()">Obriši</button>
</mat-dialog-actions>
