<div class="main-div">
 <h1 class="main-header-text mb-4">Pregled info tabli</h1>
  <form class="form-div" [formGroup]="form" (ngSubmit)="onSubmit($event)">
    <div>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Stanica</mat-label>
        <mat-select formControlName="stationId" (selectionChange)="getTracks($event)" id="stationId">
          <mat-option *ngFor="let station of stations$ | async" [value]="station.id">
            {{ station.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Kolosek</mat-label>
        <mat-select formControlName="track" id="tack">
          <mat-option *ngFor="let track of tracks$ | async" [value]="track.trackNumber"> {{ track.trackNumber }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <button mat-flat-button color="primary" type="submit" [disabled]="!form.valid || !form.get('track')!.value">Prikaži kolosečnu tablu</button>
    </div>
    <div class="mt-2">
      <button
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="!form.valid"
        (click)="arrivalDepartureTable(infoTableType.DEPARTURE)"
      >
        Prikaži tablu za polaske
      </button>
    </div>
    <div class="mt-2">
      <button
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="!form.valid"
        (click)="arrivalDepartureTable(infoTableType.ARRIVAL)"
      >
        Prikaži tablu za dolaske
      </button>
    </div>
    <div class="mt-2">
      <button
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="!form.valid"
        (click)="arrivalDepartureTable(infoTableType.ARRIVAL_DEPARTURE)"
      >
        Prikaži polazno/dolaznu tablu
      </button>
    </div>
  </form>
</div>
