import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, map, merge, Observable, skip, Subscription, switchMap, tap } from 'rxjs';
import { RealizationService } from 'src/app/services/realization.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/services/user.service';
import { MESSAGE } from 'src/app/utils/messages';
import { RealizationChangeInfo, StationRealizationItem } from '../../../../models/realization.model';
import { environment } from 'src/environments/environment';
import { RealizationDetailsComponent } from './realization-details/realization-details.component';
import { Station } from 'src/app/models/stations.model';
import { ActionStatus } from '../../../../utils/enums';
import { SearchComponent } from '../reusable-component/search/search.component';
import { SELECTED_STATION_KEY } from '../../../../utils/constants';
import { ActionsComponent } from '../actions/actions.component';
import { ActionsDirective } from '../../../../directives/actions.directive';
import { RxStompService } from '../../../../services/rx-stomp.service';
import { MatButtonToggle, MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatRadioChange, MatRadioGroup } from '@angular/material/radio';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export class RealisationDate {
  date: Date | undefined;
  constructor(date?: Date) {
    this.date = date;
  }
}

@Component({
  selector: 'app-realization',
  templateUrl: './realization.component.html',
  styleUrls: ['./realization.component.scss'],
})
export class RealizationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatRadioGroup) select: MatRadioGroup;
  @ViewChild('toggle') checkbox: MatButtonToggle;
  @ViewChild(SearchComponent) search: SearchComponent;
  @ViewChild(ActionsDirective, { static: true }) actionsHost!: ActionsDirective;
  url: string = environment.baseUrl;
  pageSize: number = 10;
  total: number;
  data: StationRealizationItem[];

  displayedColumns: string[] = [
    'ID',
    // 'OPERATOR',
    'CATEGORY',
    'TRAIN',
    'TRANZIT',
    'ROUTE',
    'KOLOSEK',
    'TIME_ARRIVAL',
    'TIME_DEPARTURE',
    'ARRIVAL_DELAY',
    'DEPARTURE_DELAY',
    'SKR',
    'STATUS',
    'ACTION_STATUS',
  ];
  subscription: Subscription = new Subscription();
  selectedStation: Station;
  actionStatus = ActionStatus;
  stationList = false;

  private initialData: EventEmitter<void> = new EventEmitter<void>();

  private realisationDate: BehaviorSubject<RealisationDate> = new BehaviorSubject<RealisationDate>(
    new RealisationDate(),
  );
  get realisationDate$(): Observable<RealisationDate> {
    return this.realisationDate.asObservable().pipe(skip(1));
  }

  archive = false;
  serializedDate: Date | null;
  maxDate = new Date();

  stations$: Observable<Station[]> = this.userService.getAuthUserStations().pipe(
    tap(stations => {
      const selected = window.localStorage.getItem(SELECTED_STATION_KEY);
      if (stations.length === 1) {
        this.storeSelectedStation(stations[0]);
        this.initialData.emit();
      } else if (selected) {
        const selectedStation = JSON.parse(selected) as Station;
        if (stations.some(station => station.id === selectedStation.id)) {
          this.selectedStation = selectedStation;
          this.initialData.emit();
        }
      }
    }),
  );

  watchRealisation$: Observable<RealizationChangeInfo> = this.rxStompService
    .watch('/realization')
    .pipe(map(response => JSON.parse(response.body)));

  constructor(
    private userService: UserService,
    private sharedService: SharedService,
    private service: RealizationService,
    private rxStompService: RxStompService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.watchRealisation$.subscribe(realisationChange => {
        const update = this.data ? [...this.data] : [];
        const change = update.find(item => item.planInstanceItem.planInstanceId === realisationChange.id);
        if (change) {
          const value = realisationChange.realizationItemChangeInfoList.find(
            item => item.id === change.planInstanceItem.id,
          );
          if (value) {
            if (undefined !== value.arrivalDelay) {
              change.realizationItem.arrivalDelay = value.arrivalDelay;
            }
            if (undefined !== value.departureDelay) {
              change.realizationItem.departureDelay = value.departureDelay;
            }
            change.realizationItem.track = value.track;
            change.shortened = realisationChange.shortened;
            change.description = value.description;
            change.actionStatus = value.actionStatus;
          }
          this.data = update;
        }
      }),
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      merge(
        this.paginator.page,
        this.select.change,
        this.checkbox.change,
        this.search.searchBox,
        this.initialData,
        this.sharedService.gridReload$,
        this.realisationDate$,
      )
        .pipe(
          switchMap(event => {
            if (
              event instanceof MatButtonToggleChange ||
              event instanceof MatRadioChange ||
              event instanceof RealisationDate ||
              (typeof event === 'object' && event.hasOwnProperty('searchText'))
            ) {
              this.paginator.pageIndex = 0;
            }
            return this.service.search(
              this.selectedStation?.id,
              this.checkbox.checked,
              this.paginator.pageIndex,
              this.paginator.pageSize,
              this.search.searchText,
              this.realisationDate.value.date,
            );
          }),
          map(data => {
            if (data === null) {
              return [];
            }
            this.total = data.totalElements;
            return data.content;
          }),
        )
        .subscribe({
          next: data => (this.data = data),
          error: (error: any) => {
            error.message
              ? this.sharedService.showMessage(error.message)
              : this.sharedService.showMessage(MESSAGE.error_get_realization);
          },
        }),
    );
  }

  loadData(): void {
    this.sharedService.reloadGrid();
  }

  storeSelectedStation(station: Station): void {
    this.selectedStation = station;
    window.localStorage.setItem(SELECTED_STATION_KEY, JSON.stringify(this.selectedStation));
  }

  openDetail(id: number) {
    this.subscription.add(
      this.service.getRealizationDetails(id).subscribe({
        next: data => {
          this.dialog.open(RealizationDetailsComponent, {
            width: '880px',
            height: '790px',
            panelClass: 'custom-dialog-container',
            data: {
              id: id,
              data: data,
            },
            autoFocus: false,
          });
        },
        error: error => {
          error?.errorMessages?.forEach((err: any) => {
            err ? this.sharedService.showMessage(err) : this.sharedService.showMessage(MESSAGE.error_get_realization);
          });
        },
      }),
    );
  }

  onActionMenuOpen(planInstanceId: number) {
    const viewContainerRef = this.actionsHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<ActionsComponent>(ActionsComponent);
    componentRef.instance.planInstanceId = planInstanceId;
    componentRef.instance.station = this.selectedStation;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onDateChange($event: MatDatepickerInputEvent<Date>) {
    this.archive = true;
    this.realisationDate.next(new RealisationDate($event.value!));
  }

  closeArchive() {
    this.archive = false;
    this.serializedDate = null;
    this.realisationDate.next(new RealisationDate());
  }
}
