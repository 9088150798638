<div class="main-div">
  <h1 class="main-header-text">Korisnici</h1>
  <!---Table--->
  <div class="flex table-top-area">
    <div>
      <app-search></app-search>
    </div>
    <div class="add-station-div">
      <button mat-icon-button disableRipple (click)="openDialog()">
        <mat-icon svgIcon="add" class="add" matTooltip="Kreiraj korisnika"></mat-icon>
      </button>
    </div>
  </div>
  <table class="table-style" mat-table [dataSource]="data" matSort>
    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id" id="user_id">ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="USERNAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="username" id="username">Korisničko ime</th>
      <td mat-cell *matCellDef="let element">{{ element.username }}</td>
    </ng-container>

    <ng-container matColumnDef="FULL-NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="fullName" id="full_name">Ime</th>
      <td mat-cell *matCellDef="let element">
        {{ element.fullName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ENABLED">
      <th mat-header-cell *matHeaderCellDef>Aktivan</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="yes" svgIcon="yes" *ngIf="element.enabled"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="STATION">
      <th mat-header-cell *matHeaderCellDef id="stations">Stanice</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngFor="let station of element.userStations; let i = index">
          {{ station.station.name }}{{ i !== element.userStations.length - 1 ? ', ' : '' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="ROLE">
      <th mat-header-cell *matHeaderCellDef id="roles">Role</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngFor="let role of element.roles; let last = last">
          {{ getUserRole(role) }}{{ !last ? ', ' : '' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="ACTION">
      <th mat-header-cell *matHeaderCellDef class="p-0">Akcije</th>
      <td mat-cell *matCellDef="let element" class="cell-size-actions">
        <div class="cell-actions">
          <button mat-icon-button (click)="manage(element.id)">
            <mat-icon svgIcon="edit" class="edit" matTooltip="Ažuriraj"></mat-icon>
          </button>
          <button mat-icon-button (click)="manage(element.id, 'role')">
            <mat-icon svgIcon="user-role" class="user-role" matTooltip="Role"></mat-icon>
          </button>
          <button mat-icon-button (click)="manage(element.id, 'station')">
            <mat-icon svgIcon="user-station" class="user-station" matTooltip="Stanice"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="paginator">
    <mat-paginator [length]="total" [pageSize]="10" [hidePageSize]="true" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>
