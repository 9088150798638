<app-display-header>
  <div class="station-info">
    <div class="label" *ngIf="data?.length">
      {{ isArrivalsDisplay ? 'Dolazak' : 'Polazak' }}<span>{{ isArrivalsDisplay ? 'Arrival' : 'Departure' }}</span>
    </div>
  </div>
</app-display-header>
<div [ngClass]="{ arrival: isArrivalsDisplay, departure: !isArrivalsDisplay }">
  <table [class.won]="!notifications?.length">
    <thead>
      <tr>
        <th scope="col" class="operator"></th>
        <th scope="col" class="train-category"></th>
        <th scope="col" class="train-id"></th>
        <th scope="col" class="station"></th>
        <th scope="col" class="track"><p class="label">kolosek <span>track</span></p></th>
        <th scope="col" class="time">
          <p class="label">{{ isArrivalsDisplay ? 'dolazak' : 'polazak' }}<span>{{ isArrivalsDisplay ? 'arrival' : 'departure' }}</span></p>
        </th>
        <th scope="col" class="delay"><p class="label">kašnjenje <span>delay</span></p></th>
        <th scope="col" class="short-notification"><p class="label">napomena <span>remark</span></p></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let stationRealizationItem of data; let i = index" [ngClass]=" i % 2 === 0 ? 'even' : 'odd' " [blink]="stationRealizationItem.planInstanceItem.id">
        <td class="operator">
          <img
            [src]="url + '/operators/' + stationRealizationItem.train.operator.id + '/small-logo'"
            class="logo"
            [alt]="stationRealizationItem.train.operator.name + ' logo'"
          />
        </td>
        <td class="train-category blink">
          {{ stationRealizationItem.train.trainCategory.code }}
        </td>
        <td class="train-id blink">
          {{ stationRealizationItem.train.id }}
        </td>
        <td class="station blink">
          {{
            isArrivalsDisplay
              ? stationRealizationItem.routeHeader.firstStation.name
              : stationRealizationItem.routeHeader.lastStation.name
          }}
        </td>
        <td class="track blink">
          {{ stationRealizationItem.realizationItem.track ?? stationRealizationItem.planInstanceItem.track }}
        </td>
        <td class="time blink">
          {{
            (isArrivalsDisplay
              ? stationRealizationItem.planInstanceItem.planTimeArrival
              : stationRealizationItem.planInstanceItem.planTimeDeparture
            ) | date : 'HH:mm'
          }}
        </td>
        <td class="delay blink">
          {{
            isArrivalsDisplay
              ? stationRealizationItem.realizationItem.arrivalDelay
              : stationRealizationItem.realizationItem.departureDelay
          }}
        </td>
        <td class="short-notification blink" [innerHTML]="stationRealizationItem.realizationItem.shortNotification"></td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="notifications?.length" class="notification-area">
    <div class="notification">
      <app-notifications [notifications]="notifications" [switchTime]="messageSwitchTime"></app-notifications>
    </div>
  </div>
</div>
