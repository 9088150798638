<ng-container *ngIf="params$ | async">
  <div *ngFor="let param of params | keyvalue : onCompare" class="param-group">
    <ng-container *ngIf="paramType.BOOLEAN === param.value.appParam.typeCode">
      <mat-slide-toggle color="primary" [(ngModel)]="param.value.booleanValue" (change)="save(param.value)">
        {{ param.value.appParam.name }}
      </mat-slide-toggle>
      <button mat-raised-button class="reset" (click)="resetDefault(param.key, param.value)">Reset</button>
    </ng-container>
    <ng-container
      *ngIf="paramType.NUMBER === param.value.appParam.typeCode || paramType.STRING === param.value.appParam.typeCode"
    >
      <mat-label class="label">{{ param.value.appParam.name }}</mat-label>
      <mat-form-field appearance="outline" floatLabel="auto">
        <input
          *ngIf="paramType.NUMBER === param.value.appParam.typeCode"
          matInput
          [(ngModel)]="param.value.numberValue"
          (input)="onInputValueChange(param.value, resetBtn, saveBtn)"
          name="{{ param.key }}"
        />
        <input
          *ngIf="paramType.STRING === param.value.appParam.typeCode"
          matInput
          [(ngModel)]="param.value.stringValue"
          (input)="onInputValueChange(param.value, resetBtn, saveBtn)"
          name="{{ param.key }}"
        />
      </mat-form-field>
      <button #resetBtn mat-raised-button class="reset" (click)="reset(param.value, resetBtn, saveBtn)" disabled>
        Otkazivanje
      </button>
      <button #saveBtn mat-raised-button color="primary" (click)="save(param.value, resetBtn, saveBtn)" disabled>
        Snimi
      </button>
      <button mat-raised-button class="reset" (click)="resetDefault(param.key, param.value)">Reset</button>
    </ng-container>
  </div>
</ng-container>
