import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notification-snack-bar',
  templateUrl: './notification-snack-bar.component.html',
  styleUrls: ['./notification-snack-bar.component.scss'],
})
export class NotificationSnackBarComponent {
  data: { icon: 'info' | 'error'; message: string; action?: string };

  constructor(public snackBarRef: MatSnackBarRef<SimpleSnackBar>, @Inject(MAT_SNACK_BAR_DATA) data: any) {
    this.data = data;
  }

  action(): void {
    this.snackBarRef.dismissWithAction();
  }

  get hasAction(): boolean {
    return !!this.data.action;
  }
}
