import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from '../../../../../services/shared.service';
import { MESSAGE } from '../../../../../utils/messages';
import { User, UserData } from '../../../../../models/user.model';
import { UserService } from '../../../../../services/user.service';
import { GridModal } from '../../../../../models/grid-modal';
import { checkPasswords } from '../../../../../utils/validators';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
})
export class UserModalComponent implements OnInit {
  userModalForm: FormGroup = this.fb.group(
    {
      username: ['', Validators.required],
      fullName: ['', Validators.required],
      password: ['', Validators.required],
      passwordRepeat: ['', Validators.required],
      enabled: [false],
    },
    { validators: checkPasswords },
  );
  isFormSubmitted: boolean = false;
  hide: boolean = true;
  newUser: UserData = new UserData('', '', '', false);
  private disabledVariable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: GridModal<User>,
    public dialogRef: MatDialogRef<UserModalComponent>,
    public sharedService: SharedService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.userModalForm.get('username')?.patchValue(this.data.content.username);
      this.userModalForm.get('fullName')?.patchValue(this.data.content.fullName);
      this.userModalForm.get('enabled')?.patchValue(this.data.content.enabled);
      this.userModalForm.get('password')?.removeValidators([Validators.required]);
      this.userModalForm.get('passwordRepeat')?.removeValidators([Validators.required]);
      if (this.userModalForm.get('username')?.value) {
        this.userModalForm.get('username')?.disable();
      }
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  setDisabled(value: boolean) {
    this.disabledVariable$.next(value);
  }

  get disabled() {
    return !this.userModalForm.valid || this.disabledVariable$.getValue();
  }

  onSubmit() {
    this.isFormSubmitted = true;
    this.newUser = this.userModalForm.value;
    if (!this.userModalForm.valid) {
      this.sharedService.showMessage(MESSAGE.error_user_input);
      return;
    }
    if (this.userModalForm.valid) {
      this.setDisabled(true);
      if (this.data) {
        this.userService
          .edit(
            {
              fullName: this.newUser.fullName,
              password: this.newUser.password,
              enabled: this.newUser.enabled,
              username: this.data.content.username,
            },
            this.data.id,
          )
          .subscribe({
            next: () => {
              this.sharedService.openSnackBar(MESSAGE.success_put);
              this.closeModal();
              this.sharedService.reloadGrid();
            },
            error: errorMessage => {
              this.setDisabled(false);
              errorMessage.appCode
                ? this.sharedService.showMessage(errorMessage.appCode)
                : this.sharedService.showMessage(MESSAGE.error_user_edit);
            },
          });
      } else {
        this.userService
          .post({
            fullName: this.newUser.fullName,
            password: this.newUser.password,
            enabled: this.newUser.enabled,
            username: this.newUser.username,
          })
          .subscribe({
            next: () => {
              this.sharedService.openSnackBar(MESSAGE.success_post);
              this.closeModal();
              this.sharedService.reloadGrid();
            },
            error: errorMessage => {
              this.setDisabled(false);
              errorMessage.appCode
                ? this.sharedService.showMessage(errorMessage.errorCode)
                : this.sharedService.showMessage(MESSAGE.error_user_save);
            },
          });
      }
    }
  }
}
