import { Component, OnInit, Optional, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, EMPTY, Observable, Subscription, tap } from 'rxjs';
import { MyErrorStateMatcher } from 'src/app/directives/error-directive.directive';
import { ActionsService } from 'src/app/services/actions.service';
import { RealizationService } from 'src/app/services/realization.service';
import { SharedService } from 'src/app/services/shared.service';
import { Action } from 'src/app/utils/enums';
import { MESSAGE } from 'src/app/utils/messages';
import { ActionComponentData, StationActionData } from '../../../../../models/realization.model';

@Component({
  selector: 'app-start-postpone-action',
  templateUrl: './start-postpone-action.component.html',
  styleUrls: ['./start-postpone-action.component.scss'],
})
export class StartPostponeActionComponent implements OnInit, OnDestroy {
  matcher = new MyErrorStateMatcher();
  delay = 0;
  name: string;
  id: number = this.data.id;
  isStart: boolean;
  private _disabled = false;
  stationsForAction$: Observable<StationActionData[]> = this.realizationService
    .stationsForAction(this.data.selectedStation.id, this.id, this.data.action)
    .pipe(
      tap(res => {
        if (res.length) {
          this.delay = res[0].delay;
        }
      }),
      catchError(error => {
        error.errorMessages && error.errorMessages.length
          ? this.sharedService.showMessage(error.errorMessages[0])
          : this.sharedService.showMessage(MESSAGE.RUNTIME_ERROR);
        this.closeDialog();
        return EMPTY;
      }),
    );
  subscription: Subscription = new Subscription();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ActionComponentData,
    public dialogRef: MatDialogRef<StartPostponeActionComponent>,
    private actionService: ActionsService,
    private sharedService: SharedService,
    private realizationService: RealizationService,
  ) {}

  ngOnInit(): void {
    if (this.data.action === Action.START) {
      this.name = 'Postavljanje na prvu stanicu';
      this.isStart = true;
    } else if (this.data.action === Action.POSTPONE) {
      this.name = 'Odložiti';
      this.isStart = false;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  onSubmit() {
    this.disabled = true;
    this.subscription.add(
      this.actionService.getData(this.data.id, this.delay, this.data.action).subscribe({
        next: () => {
          this.sharedService.openSnackBar(MESSAGE.success_post);
          this.closeDialog();
          this.sharedService.reloadGrid();
        },
        error: () => (this.disabled = false),
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
