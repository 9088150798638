<h1 mat-dialog-title>{{ data ? 'Izmeni korisnika' : 'Dodaj korisnika' }}</h1>
<form [formGroup]="userModalForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <mat-dialog-content>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label class="label-mat">Korisničko ime:</mat-label>
      <input matInput formControlName="username" name="username" autocomplete="new-username"/>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="outline">
      <mat-label class="label-mat">Puno ime:</mat-label>
      <input matInput formControlName="fullName" name="fullName"/>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="outline">
      <mat-label class="label-mat">Lozinka:</mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        formControlName="password"
        name="password"
        autocomplete="new-password"
      />
      <button
        mat-icon-button
        matSuffix
        type="button"
        tabindex="-1"
        (click)="hide = !hide"
        [attr.aria-label]="'Hide Password'"
        [attr.aria-pressed]="hide"
      >
        <img
          class="vis"
          [src]="hide ? '/assets/icons/vis.svg' : '/assets/icons/vis_off.svg'"
          alt=""
        />
      </button>
    </mat-form-field>

    <mat-form-field class="w-100 pass-repeat" appearance="outline">
      <mat-label class="label-mat">Lozinka (Unesite ponovo):</mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        formControlName="passwordRepeat"
        name="passwordRepeat"
      />
      <button
        mat-icon-button
        matSuffix
        type="button"
        tabindex="-1"
        (click)="hide = !hide"
        [attr.aria-label]="'Hide Password'"
        [attr.aria-pressed]="hide"
      >
        <img
          class="vis"
          [src]="hide ? '/assets/icons/vis.svg' : '/assets/icons/vis_off.svg'"
          alt=""
        />
      </button>
    </mat-form-field>
    <div *ngIf="userModalForm.errors?.['notSame'] && (userModalForm.controls['password'].touched || userModalForm.controls['password'].dirty)" class="alert-match">
      Lozinka mora biti ista za oba unosa!
    </div>
    <div class="mb-3">
      <mat-checkbox
        class="checkbox second-div"
        color="primary"
        formControlName="enabled"
        id="check-box"
      >Aktivan
      </mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button type="button" (click)="closeModal()">Otkaži</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="disabled">Snimi</button>
  </mat-dialog-actions>
</form>
