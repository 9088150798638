<ng-container *ngIf="stationsForAction$ | async">
  <app-action-dialog
    [title]="name"
    [submitTitle]="isStart ? 'Postavljanje na prvu stanicu' : 'Odloži'"
    [disabled]="disabled"
    (submitted)="onSubmit()"
    [planInstanceId]="data.id"
  >
    <div class="input-div">
      <mat-form-field class="w-100 name-field" appearance="outline">
        <mat-label class="label-mat">Kašnjenje</mat-label>
        <input matInput [(ngModel)]="delay" [errorStateMatcher]="matcher" type="number" />
      </mat-form-field>
    </div>
  </app-action-dialog>
</ng-container>
