import { Component, OnDestroy, OnInit } from '@angular/core';
import { RxStompState } from '@stomp/rx-stomp';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  interval,
  map,
  Observable,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { PING_MAX, PING_MIN } from '../../../../../../utils/constants';
import { ShowElement } from '../../../../../../utils/enums';
import { MESSAGE } from '../../../../../../utils/messages';
import { StationTrackRealizationResponse } from '../../../../../../models/display-realization.model';
import { StationRealizationItem } from '../../../../../../models/realization.model';
import { InfoTableService } from '../../../../../../services/info-table.service';
import { RxStompService } from '../../../../../../services/rx-stomp.service';
import { RealizationService } from '../../../../../../services/realization.service';
import { TranslateService } from '../../../../../../services/translate.service';

@Component({
  selector: 'app-display-track',
  templateUrl: './display-track.component.html',
  styleUrls: ['./display-track.component.scss'],
  providers: [InfoTableService],
})
export class DisplayTrackComponent implements OnInit, OnDestroy {
  trackStatus = ShowElement;
  data: StationTrackRealizationResponse;
  notifications: string[];
  private initData = false;
  private subscription: Subscription = new Subscription();

  private reconnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  get reconnected$(): Observable<boolean> {
    return this.reconnected.asObservable();
  }

  private getInitialData$: Observable<StationTrackRealizationResponse> = combineLatest([
    this.infoTableService.infoTableTrackParams$,
    this.reconnected$,
  ]).pipe(switchMap(([params]) => this.realizationService.get(params.stationId, params.track)));

  private watchStationTrack$: Observable<StationTrackRealizationResponse> =
    this.infoTableService.infoTableTrackParams$.pipe(
      switchMap(params =>
        this.rxStompService.watch(`/s${params.stationId}/t${params.track}`).pipe(
          map(response => JSON.parse(response.body)),
          tap(() => this.infoTableService.ackTrack(params)),
        ),
      ),
    );

  infoTableElement$ = this.infoTableService.infoTableElement$.pipe(distinctUntilChanged());

  constructor(
    private infoTableService: InfoTableService,
    private realizationService: RealizationService,
    private rxStompService: RxStompService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([interval(this.randomDelay(PING_MIN, PING_MAX)), this.rxStompService.connectionState$])
        .pipe(
          filter(([, state]) => state === RxStompState.OPEN),
          switchMap(() => this.infoTableService.infoTableTrackParams$),
        )
        .subscribe(params => {
          this.infoTableService.pingTrack(params);
        }),
    );

    this.subscription.add(
      this.rxStompService.connectionState$
        .pipe(filter(state => state === RxStompState.OPEN || state === RxStompState.CLOSED))
        .subscribe(state => {
          switch (state) {
            case RxStompState.OPEN:
              if (this.initData) {
                this.reconnected.next(true);
              }
              break;
            case RxStompState.CLOSED:
              this.infoTableService.setInfoTableElement(ShowElement.OUT_OF_ORDER);
              break;
          }
        }),
    );

    this.subscription.add(
      this.getInitialData$.subscribe({
        next: res => {
          this.initData = true;
          this.data = res;
          this.notifications = this.getNotifications(this.data.stationRealizationItems);
          this.infoTableService.setInfoTableElement(ShowElement.DISPLAY);
        },
        error: error => {
          this.infoTableService.setInfoTableElement(ShowElement.OUT_OF_ORDER);
          if (error && error.errorMessages) {
            error.errorMessages.forEach((err: any) => {
              console.log(
                err ? this.translateService.instant(err) : this.translateService.instant(MESSAGE.RUNTIME_ERROR),
              );
            });
          }
        },
      }),
    );

    this.subscription.add(
      this.watchStationTrack$.subscribe({
        next: res => {
          this.data = res;
          this.notifications = this.getNotifications(this.data.stationRealizationItems);
          this.infoTableService.setInfoTableElement(ShowElement.DISPLAY);
        },
        error: () => {
          this.infoTableService.setInfoTableElement(ShowElement.OUT_OF_ORDER);
        },
      }),
    );
  }

  protected randomDelay(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private getNotifications(stationRealizationItems: StationRealizationItem[]) {
    let notifications: string[] = [];
    stationRealizationItems?.forEach(item => {
      if (item.messages?.length) {
        notifications.push(...item.messages);
      }
    });
    return notifications;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
