import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { PlanInstance, PlanInstanceMessage, StationArrivalDeparturesResponse } from '../models/realization.model';
import { Action } from '../utils/enums';
import { MESSAGE } from '../utils/messages';
import { InfoTableType } from '../models/info-table';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  constructor(private http: HttpClient, private sharedService: SharedService) {}

  getData(planInstanceId: number, delay: number, action: Action): Observable<any> {
    let route;
    if (action === Action.POSTPONE) {
      route = 'postpone';
    } else if (action === Action.START) {
      route = 'start';
    }

    return this.http.post('actions/' + route, { planInstanceId: planInstanceId, delay: delay }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleError(error);
        throw error;
      }),
    );
  }

  resetOrCancel(planInstanceId: number, action: Action): Observable<any> {
    let url;
    if (action === Action.RESET) {
      url = '/reset';
    } else if (action == Action.CANCEL) {
      url = '/cancel';
    }

    return this.http.post('actions' + url, { planInstanceId: planInstanceId }).pipe(
      map(res => {
        return res;
      }),
    );
  }

  changeTrack(planInstanceId: number, stationId: number, track: number): Observable<any> {
    let requestBody = {
      stationId: stationId,
      planInstanceId: planInstanceId,
      track: track,
    };
    return this.http.post<any>('actions/change-track', requestBody);
  }

  handleError(error: any) {
    let errorMessage = MESSAGE.error_start_action_post;
    if (error.appCode) {
      switch (error.appCode) {
        case 'ACTION_CANNOT_COMPLETE':
          errorMessage = MESSAGE.ACTION_CANNOT_COMPLETE;
          break;
      }
    }
    this.sharedService.showMessage(errorMessage);
  }

  //Arrival departure
  getArrivalDepartureRealizations(
    stationId: number,
    infoTableType: InfoTableType,
    resultSize: number,
  ): Observable<StationArrivalDeparturesResponse> {
    let params = new HttpParams();
    params = params.append('station-id', stationId.toString());
    params = params.append('info-table-type', infoTableType);
    params = params.append('result-size', resultSize.toString());

    return this.http.get<StationArrivalDeparturesResponse>('realizations/station-arrival-departure-realizations', {
      params,
    });
  }

  arriveDepart(action: Action, stationId: number, planInstanceId: number, delay: number) {
    let route;

    switch (action) {
      case Action.DEPARTURE:
        route = 'depart';
        break;
      case Action.ARRIVAL:
        route = 'arrive';
        break;
      case Action.CHANGE_DEPARTURE_DELAY:
        route = 'change-departure-delay';
        break;
      case Action.CHANGE_ARRIVAL_DELAY:
        route = 'change-arrival-delay';
        break;
    }

    let requestBody = {
      stationId: stationId,
      planInstanceId: planInstanceId,
      delay: delay,
    };
    return this.http.post(`actions/${route}`, requestBody).pipe(
      catchError((error: any) => {
        error?.errorMessages?.forEach((err: any) => {
          err ? this.sharedService.showMessage(err) : this.sharedService.showMessage(MESSAGE.error_get_realization);
        });
        throw new Error(error?.error);
      }),
    );
  }

  getPlanInstance(id: number): Observable<PlanInstance> {
    return this.http.get<PlanInstance>(`plan-instances/${id}`).pipe(
      catchError(error => {
        error.errorMessages.forEach((err: any) => {
          err ? this.sharedService.showMessage(err) : this.sharedService.showMessage(MESSAGE.RUNTIME_ERROR);
        });
        return of({} as PlanInstance);
      }),
    );
  }

  shortening(planInstanceId: number, firstStationId: number, lastStationId: number): Observable<any> {
    let requestBody = {
      planInstanceId: planInstanceId,
      firstStationId: firstStationId,
      lastStationId: lastStationId,
    };

    return this.http.post<any>('actions/shortening', requestBody).pipe(
      catchError((error: any) => {
        error?.errorMessages?.forEach((err: any) => {
          err ? this.sharedService.showMessage(err) : this.sharedService.showMessage(MESSAGE.error_get_realization);
        });
        throw new Error(error?.error);
      }),
    );
  }

  planInstanceActionsForStation(planInstanceId: number, stationId: number): Observable<Action[]> {
    return this.http.get<Action[]>(`plan-instances/${planInstanceId}/actions`, { params: { stationId } });
  }

  getPlanInstanceMessages(planInstanceId: number): Observable<PlanInstanceMessage[]> {
    return this.http.get<PlanInstanceMessage[]>(`plan-instances/${planInstanceId}/messages`);
  }

  createPlanInstanceMessage(planInstanceId: number, message: string): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<void>(`plan-instances/${planInstanceId}/messages`, message, { headers });
  }

  deletePlanInstanceMessage(planInstanceId: number, messageId: number): Observable<void> {
    return this.http.delete<void>(`plan-instances/${planInstanceId}/messages/${messageId}`);
  }
}
