<div class="main-div">
  <h1 class="main-header-text">Poslovi</h1>
  <!---Table--->
  <table class="table-style" mat-table [dataSource]="data" matSort>
    <ng-container matColumnDef="ID" class="id-width">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="TYPE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="type">TIP</th>
      <td mat-cell *matCellDef="let element">{{ element.type }}</td>
    </ng-container>

    <ng-container matColumnDef="STATUS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="status">STATUS</th>
      <td mat-cell *matCellDef="let element">
        {{ element.status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ERROR">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="error">OPIS</th>
      <td mat-cell *matCellDef="let element">
        {{ element.error ? element.error : element.summary }}
      </td>
    </ng-container>

    <ng-container matColumnDef="CREATED_DATE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">VREME POČETKA</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdDate | date : 'dd.MM.yyyy - HH:mm:ss' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="UPDATE_DATE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedDate">VREME ZAVRŠETKA</th>
      <td mat-cell *matCellDef="let element">
        {{ element.updatedDate | date : 'dd.MM.yyyy - HH:mm:ss' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="paginator">
    <mat-paginator [length]="total" [pageSize]="10" [hidePageSize]="true" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>
