<app-action-dialog
  [title]="'Skraćenje rute'"
  [submitTitle]="'Snimi'"
  [disabled]="disabled"
  (submitted)="onSubmit()"
  [planInstanceId]="data.id"
>
  <form [formGroup]="form">
    <div class="station-1">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Početna stanica:</mat-label>
        <mat-select #start formControlName="startRoute" [errorStateMatcher]="matcher">
          <mat-option *ngFor="let station of start$ | async" [value]="station.station.id">
            {{ station.station.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="station-2">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label class="label-mat">Krajnja stanica:</mat-label>
        <mat-select #end formControlName="endRoute" [errorStateMatcher]="matcher">
          <mat-option *ngFor="let station of end$ | async" [value]="station.station.id">
            {{ station.station.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</app-action-dialog>
