<div class="main-div">
  <h1 class="main-header-text">Stanice</h1>
  <!---Table--->
  <div class="flex table-top-area">
    <div>
      <app-search></app-search>
    </div>
    <div class="add-station-div">
      <button mat-icon-button disableRipple (click)="openDialog()">
        <mat-icon svgIcon="add" class="add" matTooltip="Kreiraj stanicu"></mat-icon>
      </button>
    </div>
  </div>
  <table class="table-style" mat-table [dataSource]="data" matSort>
    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id" id="station_id">ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="STATION-TYPE">
      <th mat-header-cell *matHeaderCellDef>Tip</th>
      <td mat-cell *matCellDef="let element">
        {{ element.stationType == 'STATION' ? 'Stanica' : element.stationType == 'STOP' ? 'Stajalište' : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="STATION-NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name" id="station_name">Naziv</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="LICENCE">
      <th mat-header-cell *matHeaderCellDef>Licenca</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="yes" svgIcon="yes" *ngIf="element.licensed"></mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="EXTERNAL-ID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="externalId" id="external_id">Eksterni ID</th>
      <td mat-cell *matCellDef="let element">{{ element.externalId }}</td>
    </ng-container>
    <ng-container matColumnDef="ACTION">
      <th mat-header-cell *matHeaderCellDef class="p-0">Akcije</th>
      <td mat-cell *matCellDef="let element" class="cell-size-actions">
        <div class="cell-actions">
          <button mat-icon-button (click)="getById(element.id)">
            <mat-icon svgIcon="edit" class="edit" matTooltip="Ažuriraj"></mat-icon>
          </button>
          <button mat-icon-button (click)="addTrack(element.id, element.name)">
            <mat-icon svgIcon="station-track" class="station-track" matTooltip="Kolosek"></mat-icon>
          </button>
          <button mat-icon-button (click)="addSubsequentStation(element.id, element.name)">
            <mat-icon svgIcon="station-subsequent" class="station-subsequent" matTooltip="Susedna stanica"></mat-icon>
          </button>
          <button mat-icon-button (click)="addStationPremises(element.id, element.name)">
            <mat-icon svgIcon="station-premises" class="station-premises" matTooltip="Prostorija"></mat-icon>
          </button>
          <button mat-icon-button (click)="deleteDialog(element)">
            <mat-icon svgIcon="delete" class="delete" matTooltip="Obriši"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="paginator">
    <mat-paginator [length]="total" [pageSize]="10" [hidePageSize]="true" [showFirstLastButtons]="true">
    </mat-paginator>
  </div>
</div>
