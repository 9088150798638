import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { formatDateCustom } from '../utils/date-time';

@Injectable()
export class ReportService {
  constructor(private client: HttpClient) {}

  dailyStationAudioMessages(stationId: number, day: Date): Observable<HttpResponse<Blob>> {
    return this.client.get('reports/daily-station-audio-messages', {
      params: { stationId, day: formatDateCustom(day) },
      observe: 'response',
      responseType: 'blob',
    });
  }
}
