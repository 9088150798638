<div class="login-bg">
  <div class="overlay">
    <mat-card class="mat-card">
      <div class="logo">
        <img src="/assets/img/avis-logo-login.png" alt="logo" />
      </div>
      <div class="header-div">
        <h3>AUDIO VIZUELNI INFORMACIONI SISTEM</h3>
      </div>
      <div class="form-div">
        <form #authForm="ngForm" (ngSubmit)="onSubmit()">
          <mat-form-field appearance="outline">
            <mat-label>Korisničko ime</mat-label>
            <input matInput type="text" name="username" placeholder="Unesite korisničko ime" ngModel required />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Lozinka</mat-label>
            <input matInput type="password" name="password" placeholder="Unesite šifru" ngModel required />
          </mat-form-field>
          <div class="btn-div">
            <button mat-flat-button color="primary" type="submit">Prijavi se</button>
          </div>
        </form>
      </div>
    </mat-card>
  </div>
  <div class="copyright">
    Copyright © Enetel Solutons 2023
  </div>
</div>
