import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AudioMessageData, AudioMessageRequest } from '../models/audio-message.model';
import { IPage } from '../models/shared.model';

@Injectable({
  providedIn: 'root',
})
export class AudioMessageService {
  constructor(private http: HttpClient) {}

  get(
    stationId: number,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string,
    search?: string,
  ): Observable<IPage<AudioMessageData>> {
    let paramsObject: { [k: string]: number | string } = {
      stationId: stationId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDirection: sortDirection,
    };
    if (search) {
      paramsObject['search'] = search;
    }
    const params = new HttpParams({ fromObject: paramsObject });
    return this.http.get<IPage<AudioMessageData>>(`audio/search`, { params });
  }

  post(data: AudioMessageRequest): Observable<void> {
    return this.http.post<void>('audio/audio-messages', data);
  }
}
